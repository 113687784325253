import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import config from '../Common_Files/Config';

const BankSuggestionBox = ({ bank_id, onBankChange }) => {
    const accessToken = localStorage.getItem('accessToken');
    const [bankOptions, setBankOptions] = useState([]);
    const [bankFilteredOptions, setBankFilteredOptions] = useState([]);
    const [bankValue, setBankValue] = useState(null); 
    const [bankInputValue, setBankInputValue] = useState('');

    const bankHandleChange = (selectedOption) => {
        setBankValue(selectedOption);
        if (onBankChange) {
            onBankChange(selectedOption);  
        }
    };

    const bankHandleInputChange = (inputValue) => {
        setBankInputValue(inputValue);
        const newFilteredOptions = bankOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setBankFilteredOptions(newFilteredOptions);
    };

    const fetchBankList = async () => {
        const apiBankUrl = `${config.api_url}/ws-get-all-bank`;
        try {
            const response = await fetch(apiBankUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            const formattedOptions = data.bank.map(item => ({
                label: item.bank_name,
                value: item.id,
            }));
            setBankOptions(formattedOptions);
            setBankFilteredOptions(formattedOptions);
        } catch (error) {
            console.error('API failed: error fetching bank', error);
        }
    };

    useEffect(() => {
        if (accessToken === null) {
            window.location.href = '/login';
            return;
        }
        fetchBankList();
    }, []);

    useEffect(() => {
        if (bank_id) {
            const selectedCategory = bankOptions.find(option => option.value === bank_id);
            setBankValue(selectedCategory || null);
        }
    }, [bank_id, bankOptions]);

    return (
        <Select
            className='promoselect abovedd abovedd-cat'
            isClearable
            onInputChange={bankHandleInputChange}
            onChange={bankHandleChange}
            options={bankFilteredOptions}
            placeholder="Select Bank"
            value={bankValue}
            name="bank"
        />
    );
}

export default BankSuggestionBox;
