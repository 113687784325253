import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { format } from 'date-fns';

const PurchaseProforma = () => {
    const { id } = useParams();
    const accessToken = localStorage.getItem('accessToken');
    const [productList, setProductList] = useState([]);
    const [supplierAddress, setSupplierAddress] = useState([]);

     let subtotal = 0;
     let gross_weight =0;
     let net_weight =0;
     let cbm =0;

    const fetchData = async () => {
        const formData = new FormData();
        formData.append('id', id);

        const apiUrl = `${config.api_url}/ws-get-purchase-order`;
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to fetch purchase data');
            }

            const data = await response.json();
            setProductList(data.products);
            setSupplierAddress(data.headerSection2);

        } catch (error) {
            console.error('Error fetching purchase data:', error);
        }
    };

    useEffect(() => {
        if (!accessToken) {
            window.location.href = '/login';
            return;
        }
        fetchData();
    }, []);

  const calculateAmount = (quantity, price) => {
    const qty = Number(quantity);
    const prc = Number(price);
    return isNaN(qty) || isNaN(prc) ? 0 : qty * prc;
};
    const calculateVAT = (amount, rate) => {
    const amt = Number(amount);
    const r = Number(rate);
    return isNaN(amt) || isNaN(r) ? 0 : (amt * r) / 100;
};

   const calculateTotalPrice = (quantity, price, rate) => {
    const amt = calculateAmount(quantity, price); // Ensure this returns a number
    const vat = calculateVAT(amt, rate); // Ensure this returns a number
    return isNaN(amt) || isNaN(vat) ? 0 : amt + vat; // Return 0 if either is NaN
};




    return (
        <div>
        <Header />
        <div className="breadcrumbs">
        <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-lg-12 col-sm-12">
        <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="dashboard">Dashboard</a> &gt; <span className="last-crumb">Purchase Order </span>
        </div>
        </div>
        </div>
        </div>
        <div className="row">
        <div className="col-12">
        <div className="show-order-area dashboard">
        <div className="sales-view-area" style={{ width: '100%', border: 'none' }}>
        <div className="row">
        <div className="col-7">
        <button>Generate PDF</button>
        </div>
        </div>

        <div className="row page-header" style={{ paddingTop: '10px' }}>
        <div className="col-3">
        <h5>NBC</h5>
        <p>Abenglen Industrial Estate<br />Middlesex, London UB3 1AT<br />United Kingdom</p>
        </div>
        <div className="col-3">
        <ul>
        <li><b>Co. Reg. No :</b> 7298396</li>
        <li><span>VAT Reg. No.:</span> GB994533184</li>
        <li><span>Tel :</span> 44-208 329 3800</li>
        </ul>
        </div>
        </div>
        <div className="row page-header" style={{ paddingTop: '10px' }}>
        <div className="col-3">
        <ul>
        <li><b>Supplier Address : </b>  {supplierAddress.address}</li>
        <li><span> {supplierAddress.city}</span></li>
        <li><span>{supplierAddress.country}{supplierAddress.postcode}</span></li>

        </ul>
        </div>
        <div className="col-3">
        <ul>
        <li><b>VAT Reg. No :</b> {supplierAddress.vat_id}</li>
        <li><span>Rep : </span> {supplierAddress.contact_person_name }</li>
        <li><span>Account No :</span> {supplierAddress.bank_account_number}</li>
        </ul>
        </div>
        <div className="col-3">
        <ul>
        <li><span>PO No :</span>  {supplierAddress.reference_no}</li>
        <li><span>Date : </span>  06.07.2021</li>
        <li><span>Jon Ref :</span> {supplierAddress.job_ref}</li>
        </ul>
        </div>
        <div className="col-3">
        <ul>
        <li><span>Currency :</span> {supplierAddress.currency_id}</li>
        <li><span>Date of Arrival : </span> {supplierAddress.arrival_date}</li>

        <li><span>SO# :</span> </li>
        </ul>
        </div>
        </div>
        <div className="row page-header" style={{ paddingTop: '10px' }}>
        <div className="col-3">
        <ul>
        <li><b>Delivery Address : </b> {supplierAddress.name}</li>
        <li><span>{supplierAddress.address_line1}</span></li>
        <li><span>{supplierAddress.address_line2}</span></li>
        <li><span>{supplierAddress.city}</span></li>
        <li><span>{supplierAddress.state}</span></li>
        <li><span>{supplierAddress.country} {supplierAddress.postcode}</span></li>
        </ul>
        </div>
        <div className="col-3">                                    
        </div>


        </div>
        <div className="sales-view-table">
       <table className="table table-bordered" id="purchaseProforma">
    <thead>
        <tr>
            <th style={{ backgroundColor: '#eee9e9' }}>Line No</th>
            <th style={{ backgroundColor: '#eee9e9' }}>SKU Code</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Product Description</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Pack</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Size</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Unit Barcode</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Case Qty</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Unit Price</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Amount</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Vat Rate (%)</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Vat Amount</th>
            <th style={{ backgroundColor: '#eee9e9' }}>Total Amount</th>
        </tr>
    </thead>
   <tbody>
    {


        productList.map((product, index) => {
        const amount = calculateAmount(product.quantity, product.price);
        const vat = calculateVAT(amount, product.vat);
        const totalPrice = calculateTotalPrice(product.quantity, product.price, product.rate);
          gross_weight += product.gross_weight;
          net_weight += product.net_weight;
          cbm += product.cbm;
        
        subtotal += amount; 
        
        return (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{product.code}</td>
                <td>{product.product_desc}</td>
                <td>{product.pack}</td>
                <td>{product.size}</td>
                <td>{product.unit_barcode}</td>
                <td>{product.quantity}</td>
                <td>{product.price}</td>
                <td><span>{amount.toFixed(2)}</span></td>
                <td>{product.rate}</td>
                <td><span>{vat.toFixed(2)}</span></td>
                <td><span>{totalPrice.toFixed(2)}</span></td>
            </tr>
        );
    })}
</tbody>
<tfoot>
    <tr>
        <td colSpan={8} style={{ textAlign: 'right', fontWeight: 'bold' }}>Total:</td>

        <td><span>{subtotal.toFixed(2)}</span></td>
        <td></td>

        <td><span>{productList.reduce((acc, product) => {
            const amount = calculateAmount(product.quantity, product.price);
            return acc + calculateVAT(amount, product.rate);
        }, 0).toFixed(2)}</span></td>
        <td><span>{productList.reduce((acc, product) => {
            return acc + calculateTotalPrice(product.quantity, product.price, product.rate);
        }, 0).toFixed(2)}</span></td>
    </tr>
</tfoot>

</table>

        </div>

        <div className="row page-header" style={{ paddingTop: '10px' }}>
        <div className="col-7">
        <ul>
        <li><span>Notes : </span>  Delivered on 15/08; N5267</li>
        <li><span>Ref : </span> VBG 5KG; Transportify</li>
        <li></li>

        </ul>
        </div>
        <div className="col-2">
        </div>
        <div className="col-3">
        <ul>
        <li><span>Gross Weight: {gross_weight} KGS</span></li>
        <li><span>CBM: {cbm} M3</span></li>
        <li><span>Net weight: {net_weight} KGS</span></li>
        </ul>
        </div>

        </div>
        </div>
        </div>
        </div>
        </div>
         <footer className="text-center mt-4">
        <p className="lh-base">
          <strong>Subject to NBC terms and conditions of sale, a copy of which is available on request.</strong><br />
        </p>
        <hr />
        <p>
          Reservation of Title. The property in and the title to all goods sold by NBC shall not pass to the buyer until all monies due and owing have been paid in full
        </p>
      </footer>
        </div>
        );
    };

    export default PurchaseProforma;
