// index.js
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Common_Files/Login";
import Dashboard from "./components/Common_Files/Dashboard";
import Management_Dashboard from "./components/Common_Files/Management_Dashboard";
import Operations_Dashboard from "./components/Common_Files/Operations_Dashboard";
//Master Routes
import Products from './components/Master/Products';
import ViewProduct from './components/Master/ViewProduct';
import Supplier from './components/Master/Supplier';
import Customer from './components/Master/Customer';
import WarehouseMaster from './components/Master/WarehouseMaster';
import ProductCategoryMaster from './components/Master/ProductCategoryMaster';
import Brand from './components/Master/Brand';
import UserList from './components/user/UserList';
import Accounting_Code_Master from './components/Master/Accounting_Code_Master';
import ProductBatch from './components/Purchase/ProductBatch';

//Purchase Routes
import NewPurchaseOrder from './components/Purchase/NewPurchaseOrder';
import EditNewPurchaseOrder from './components/Purchase/EditNewPurchaseOrder';
import GRN from './components/Purchase/GRN';
import ConfirmGRN from './components/Purchase/ConfirmGRN';
import PurchaseHistory from './components/Purchase/PurchaseHistory';
import PurchaseProforma from './components/Purchase/PurchaseProforma';
import EditPO from './components/Purchase/EditPO';
import PurchaseHistoryView from './components/Purchase/PurchaseHistoryView';

import PartialGRN from './components/Purchase/PartialGRN';
import CurrentSalesOrder from './components/Sales/CurrentSalesOrder';
import NewSalesOrder from './components/Sales/NewSalesOrder';
import NewSalesOrderEdit from './components/Sales/NewSalesOrderEdit';
import Proforma from './components/Sales/Proforma';
import DeliveryNote from './components/Sales/DeliveryNote';
import CreditNote from './components/Sales/CreditNote';
import ResizableTable from './components/Sales/ResizableTable';
import CreatePO from './components/Sales/CreatePO';
import SalesCollectionNote from './components/Sales/SalesCollectionNote';

import OrderHistory from './components/Sales/OrderHistory';
import DeleteOrder from './components/Sales/DeleteOrder';
import Inventory from "./components/Purchase/Inventory";
import Bank from "./components/Master/Bank";
//import NestedPopup from "./components/Master/NestedPopup";
import InvoiceView from './components/Sales/InvoiceView';

import SupplierBill from "./components/Accounts/SupplierBill";
import PayBills from "./components/Accounts/PayBills";
import PayBillsEdit from "./components/Accounts/PayBillsEdit";
import SupplierCredit from "./components/Accounts/SupplierCredit";
import PurchaseLedger from "./components/Accounts/PurchaseLedger";
import EditSupplierBill from "./components/Accounts/EditSupplierBill";


import SalesLedger from "./components/Accounts/SalesLedger";
import ReceivePayment from "./components/Accounts/ReceivePayment";
import JournalEntry from "./components/Accounts/JournalEntry";
import ReceivePaymentEdit from "./components/Accounts/ReceivePaymentEdit";
import Group_Permissions from "./components/Settings/Group_Permissions";
import Change_Permissions from "./components/Settings/Change_Permissions";

function App() {
  return (
    <Router>
      <Routes>     
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/ProductBatch" element={<ProductBatch />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/Management_Dashboard" element={<Management_Dashboard />} />
        <Route path="/Operations_Dashboard" element={<Operations_Dashboard />} />
        <Route path="/Products" element={<Products />} />
        <Route path="/ViewProduct" element={<ViewProduct />} />
        <Route path="/Supplier" element={<Supplier />} />
        <Route path="/Customer" element={<Customer />} />
        <Route path="/UserList" element={<UserList />} />
        <Route path="/NewPurchaseOrder" element={<NewPurchaseOrder />} />
        <Route path="/EditNewPurchaseOrder/:id" element={<EditNewPurchaseOrder />} />
        <Route path="/GRN" element={<GRN />} />
        <Route path="/ConfirmGRN/:id" element={<ConfirmGRN />} />
        <Route path="/PartialGRN" element={<PartialGRN />} />
        <Route path="/PurchaseHistory" element={<PurchaseHistory />} />   
        <Route path="/PurchaseProforma/:id" element={<PurchaseProforma />} />
        <Route path="/EditPO/:id" element={<EditPO />} />
        <Route path="/PurchaseHistoryView/:id" element={<PurchaseHistoryView />} />        

        <Route path="/WarehouseMaster" element={<WarehouseMaster />} />
        <Route path="/ProductCategoryMaster" element={<ProductCategoryMaster />} />
        <Route path="/Brand" element={<Brand />} />
        <Route path="/CurrentSalesOrder" element={<CurrentSalesOrder />} />
        <Route path="/NewSalesOrderEdit/:id" element={<NewSalesOrderEdit />} />
        <Route path="/NewSalesOrder" element={<NewSalesOrder />} />
        <Route path="/ResizableTable" element={<ResizableTable />} />
        <Route path="/CreatePO/:id" element={<CreatePO />} />
        <Route path="/salesCollectionNote/:id" element={<SalesCollectionNote />} />
                
        <Route path="/Proforma" element={<Proforma />} />
        <Route path="/DeliveryNote" element={<DeliveryNote />} />
        <Route path="/CreditNote" element={<CreditNote />} />
        <Route path="/Inventory" element={<Inventory />} />
        <Route path="/WarehouseMaster" element={<WarehouseMaster />} />
        <Route path="/ProductCategoryMaster" element={<ProductCategoryMaster />} />
        <Route path="/OrderHistory" element={<OrderHistory />} />
        <Route path="/DeleteOrder" element={<DeleteOrder />} />
        <Route path="/Bank" element={<Bank />} />
        <Route path="/Accounting_Code_Master" element={<Accounting_Code_Master />} />
        {/* <Route path="/NestedPopup" element={<NestedPopup />} /> */}
        <Route path="/SupplierBill" element={<SupplierBill />} />
        <Route path="/PayBills" element={<PayBills />} />
        <Route path="/PayBills/:id" element={<PayBillsEdit />} />
        <Route path="/SupplierCredit" element={<SupplierCredit />} />
        <Route path="/PurchaseLedger" element={<PurchaseLedger />} />
        <Route path="/EditSupplierBill/:id" element={<EditSupplierBill />} />
        <Route path="/SalesLedger" element={<SalesLedger />} />
        <Route path="/ReceivePayment" element={<ReceivePayment />} />
        <Route path="/ReceivePaymentEdit/:id" element={<ReceivePaymentEdit />} />
        <Route path="/JournalEntry" element={<JournalEntry />} />
        <Route path="/DeliveryInvView/:id" element={<InvoiceView />} />

        <Route path="/Group_Permissions" element={<Group_Permissions />} />
        <Route path="/Change_Permissions" element={<Change_Permissions />} />
      </Routes>
    </Router>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);
