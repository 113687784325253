import React, { useState, useEffect, useRef } from "react";
import Header from "../Common_Files/Header";
import Footer from "../Common_Files/Footer";
import config from "../Common_Files/Config";
import AddCustomerModal from "../Master/Add/AddCustomerModal";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const Customer = () => {
  const [customerData, setCustomerData] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const [editDeliveryAddresses, setEditDeliveryAddresses] = useState([]);
  const [viewDeliveryAddresses, setViewDeliveryAddresses] = useState([]);
  const [showCreditFields, setShowCreditFields] = useState(false);
  const [defaultDeliveryIndex, setDefaultDeliveryIndex] = useState(null);
  const [bankData, setBankData] = useState([]);
  const modalRef = useRef(null);
  const editModalRef = useRef(null);
  const tableRef = useRef(null);
  const accessToken = localStorage.getItem("accessToken");

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);

  const initializeDataTable = () => {
    if (!accessToken) {
      window.location.href = "/login";
      return;
    }
    const $table = $(tableRef.current);
    const searchValue = $("#searchValue").val();
    if ($.fn.DataTable.isDataTable($table)) {
      $table.DataTable().destroy();
    }

    let i = 0;
    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${config.api_url}/ws-customer-list`,
        type: "POST",
        data: function (d) {
          return $.extend({}, d, {
            draw: d.draw,
            start: d.start,
            length: d.length,
            searchValue: searchValue,
          });
        },
        dataSrc: function (json) {
          return json.data;
        },
      },
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },

      columns: [
        {
          data: "code",
          width: "6%",
          render: function (data, type, row) {
            return `<a href="#" data-bs-toggle="modal" data-id="${row.id}" class="viewbtn editbtn" data-bs-target="#view_customer" >${row.code}</a>`;
          },
        },
        { data: "company_name" },
        { data: "contact_person_name" },
        { data: "credit_status", width: "7%" },
        {
          data: null,
          width: "7%",
          render: function (data, type, row) {
            return '<a href="#"  > 0</a>';
          },
        },
        {
          data: null,
          width: "7%",
          render: function (data, type, row) {
            return '<a href="#"> 0</a>';
          },
        },
        {
          data: "status",
          width: "7%",
          render: function (data, type, row) {
            if (data == 1) {
              return "Active";
            } else {
              return "Inactive";
            }
          },
        },
        {
          data: null,
          width: "7%",
          render: function (data, type, row) {
            return `
        <a href="#" class="viewbtn" data-id="${row.id}" title="View Customer" data-bs-toggle="modal" data-bs-target="#view_customer">
        <i class="fa fa-eye"></i> 
        </a>
        <a href="#" class="editbtn" data-id="${row.id}" title="Edit Customer" data-bs-toggle="modal" data-bs-target="#edit_customer">
        <i class="fa fa-edit"></i> 
        </a>`;
          },
        },
      ],

      columnDefs: [
        {
          orderable: false,
          targets: "_all",
        },
      ],

      pageLength: 50,
    });

    // Event delegation for edit and view actions
    $table.on("click", ".editbtn", function () {
      const id = $(this).data("id");
      handleEditClick(id);
    });

    $table.on("click", ".viewbtn", function () {
      const id = $(this).data("id");
      handleViewClick(id);
    });
  };

  useEffect(() => {
    initializeDataTable();

    return () => {
      if (tableRef.current && $.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, []);

  const submitNewCustomerForm = async () => {
    const formData = $("#add_customer_form").serialize();
    try {
      const response = await fetch(`${config.api_url}/ws-add-customer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          authorization: `Bearer ebu`,
        },
        body: formData,
      });

      const result = await response.json();

      if (result.success) {
        Swal.fire({
          title: "Success!",
          text: "Customer added successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });

        setShowModal(false);
        initializeDataTable();
       
      }
    } catch (error) {
      console.error("Error accepting order:", error);
    }
  };

  const submitUpdateCustomerForm = async (e) => {
    e.preventDefault();

    try {
      var name = $("#edit_name").val();
      var contact_person_name = $("#edit_contact_person_name").val();
      var country = $("#edit_country").val();
      var county = $("#edit_b_county").val();
      var b_address1 = $("#edit_b_address1").val();
      var b_address2 = $("#edit_b_address2").val();
      var b_county = $("#edit_b_county").val();
      var b_city = $("#edit_b_city").val();
      var b_postcode = $("#edit_b_postcode").val();

      var return_check = 0;

      if (name === "") {
        $("#edit_name_error").show();
        return_check = 1;
      } else {
        $("#edit_name_error").hide();
      }

      if (contact_person_name === "") {
        $("#edit_contact_person_name_error").show();
        return_check = 1;
      } else {
        $("#edit_contact_person_name_error").hide();
      }

      if (country === "") {
        $("#edit_country_error").show();
        return_check = 1;
      } else {
        $("#edit_country_error").hide();
      }

      if (county === "") {
        $("#edit_b_country_error").show();
        return_check = 1;
      } else {
        $("#edit_b_country_error").hide();
      }

      if (b_address1 === "") {
        $("#edit_b_address1_error").show();
        return_check = 1;
      } else {
        $("#edit_b_address1_error").hide();
      }

      if (b_address2 === "") {
        $("#edit_b_address2_error").show();
        return_check = 1;
      } else {
        $("#edit_b_address2_error").hide();
      }

      if (b_county === "") {
        $("#edit_b_county_error").show();
        return_check = 1;
      } else {
        $("#edit_b_county_error").hide();
      }

      if (b_city === "") {
        $("#edit_b_city_error").show();
        return_check = 1;
      } else {
        $("#edit_b_city_error").hide();
      }

      if (b_postcode === "") {
        $("#edit_b_postcode_error").show();
        return_check = 1;
      } else {
        $("#edit_b_postcode_error").hide();
      }

      if (return_check == 1) {
        return;
      }

      const formElement = document.getElementById("edit_customer_form");
      const formData = new FormData(formElement);

      // Update status based on checkbox
      formData.set("status", formData.get("status") === "on" ? 1 : 0);

      const response = await axios.post(
        `${config.api_url}/ws-update-customer`,
        formData, // Corrected to pass formData here
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        if (editModalRef.current) {
          const bsModal = window.bootstrap.Modal.getInstance(
            editModalRef.current
          );
          if (bsModal) bsModal.hide();
        }

        Swal.fire({
          title: "Success!",
          text: "Customer updated successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          if (editModalRef.current) {
            const bsModal = window.bootstrap.Modal.getInstance(
              editModalRef.current
            );
            if (bsModal) bsModal.hide();
          }
          initializeDataTable();
          formElement.reset(); // Reset form using formElement directly
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "An error occurred while updating a customer.",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Request Error:", error);
      Swal.fire({
        title: "Error!",
        text: "An error occurred while updating a customer.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  const addNewAddress = () => {
    setDeliveryAddresses([...deliveryAddresses, {}]);
  };

  const editNewAddress = () => {
    setEditDeliveryAddresses([...editDeliveryAddresses, {}]);
  };const removeAddress = (index) => {
    let isChecked = $(".checkDefault:checked").val();
    setDeliveryAddresses(deliveryAddresses.filter((_, i) => i !== index));
    if (isChecked != "1" || deliveryAddresses.length <= 1) {
      $("#defaultDeliveryAdr").prop("checked", true);
    }
  };

  const removeEditAddress = (index) => {
    let isCheckedEdit = $(".checkDefaultEdit:checked").val();
    setEditDeliveryAddresses(
      editDeliveryAddresses.filter((_, i) => i !== index)
    );

    if (isCheckedEdit != "1" || editDeliveryAddresses.length <= 1) {
      $("#defaultDeliveryAdrEdit").prop("checked", true);
    }
  };

  

  const displayInputsForCreditFacility = (e) => {
    setShowCreditFields(e.target.checked);
  };

  const emptyCusomerAddModal = () => {
    setDeliveryAddresses([]);
    document.getElementById("add_customer_form").reset();
  };

  const handleEditClick = async (id) => {
    $("#edit_name_error").hide();
    $("#edit_contact_person_name_error").hide();
    $("#edit_country_error").hide();
    $("#edit_b_address1_error").hide();
    $("#edit_b_address2_error").hide();
    $("#edit_b_country_error").hide();
    $("#edit_b_city_error").hide();
    $("#edit_b_postcode_error").hide();
    $("#edit_b_county_error").hide();

    document.getElementById("edit_customer_form").reset();
    const formData = new FormData();
    formData.set("customer_id", id);
    const response = await axios.post(
      `${config.api_url}/ws-get-customer-list-by-id`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    let customer = response.data.customer;
    setEditDeliveryAddresses(response.data.delivery_addresses);

    if (customer) {
      $("#customer_id").val(id);
      $("#edit_name").val(customer.company_name);
      $("#edit_contact_person_name").val(customer.contact_person_name);
      $("#edit_contact_person_email").val(customer.contact_person_email);
      $("#edit_contact_person_mobile").val(customer.contact_person_mobile);
      $("#edit_country").val(customer.country);
      $("#edit_b_address1").val(customer.billing_address_line1);
      $("#edit_b_city").val(customer.billing_city);
      $("#edit_b_address2").val(customer.billing_address_line2);
      $("#edit_b_country").val(customer.billing_country);
      $("#edit_b_county").val(customer.billing_county);
      $("#edit_b_postcode").val(customer.billing_postcode);
      customer.status == 1
        ? $("#edit_status").prop("checked", true)
        : $("#edit_status").prop("checked", false);

      if (customer.credit_status == "Y") {
        $("#edit_credit_facility").prop("checked", true);
        setShowCreditFields(true);
        $("#edit_credit_limit").val(customer.credit_amount);
        //  $('#edit_credit_days').val(customer.credit_days);
      } else {
        $("#edit_credit_facility").prop("checked", false);
        setShowCreditFields(false);
      }

      $("#edit_currency").val(customer.currency).change();
      getBankByCurrency({ target: { value: customer.currency } });
      $("#bankEdit").val(customer.bank_id).change();
    }
  };

  const handleViewClick = async (id) => {
    document.getElementById("view_customer_form").reset();
    const formData = new FormData();
    formData.set("customer_id", id);
    const response = await axios.post(
      `${config.api_url}/ws-get-customer-list-by-id`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    let customer = response.data.customer;
    let deliveryAddress = response.data.delivery_addresses;
    if (customer) {
      $("#view_customerId").val(customer.code);
      $("#view_contact_person_name").val(customer.contact_person_name);
      $("#view_vat").val(customer.vat_number);
      $("#view_comapny_name").val(customer.company_name);
      $("#view_email").val(customer.contact_person_email);
      $("#view_mobile").val(customer.contact_person_mobile);
      $("#view_addr1").val(customer.billing_address_line1);
      $("#view_addr2").val(customer.billing_address_line2);
      $("#view_city").val(customer.billing_city);
      $("#view_country").val(customer.billing_country);
      $("#view_postcode").val(customer.billing_postcode);
      $("#customer_status").html(
        customer.status
          ? 'Status : <span style="margin-left: 5px;" class="badge bg-success">Active</span>'
          : 'Status : <span style="margin-left: 5px;" class="badge bg-secondary"> Inactive</span>'
      );
      setViewDeliveryAddresses(deliveryAddress);
    }
  };

  $(".default_option").click(function () {
    $(this).parent().toggleClass("active");
  });

  $(".select_ul li").click(function () {
    var currentele = $(this).html();
    $(".default_option li").html(currentele);
    $(this).parents(".select_wrap").removeClass("active");
  });

  const getBankByCurrency = async (e) => {
    const currency = e.target.value;
    const apiUrl = `${config.api_url}/ws-bank-by-currency`;

    var formData = new FormData();
    formData.append("currency", currency);

    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      const data = response.data;
      setBankData(data.bank);
    } catch (error) {
      console.error("Error fetching bank data:", error);
    }
  };

  const getAddressById = (id) => {
    let address = viewDeliveryAddresses.find((item) => item.id == id);
    $("#view_billing_adr1").val(address.address_line1);
    $("#view_billing_adr2").val(address.address_line2);
    $("#view_billing_city").val(address.city);
    $("#view_billing_country").val(address.country);
    $("#view_billing_postcode").val(address.post_code);
  };

  return (
    <div>
      {/* Add Customer Modal */}
      <button onClick={() => setShowModal(true)}>Add Customer</button>
      <AddCustomerModal
        show={showModal}
        onHide={handleCloseModal}
        onSubmit={submitNewCustomerForm}
      />
































      {/* Edit Customer Modal */}
      <div
        className="modal fade"
        id="edit_customer"
        tabIndex="-1"
        ref={editModalRef}
      >
        <form id="edit_customer_form">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Customer</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={emptyCusomerAddModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="add-product-popup">
                  <h4>Customer Details</h4>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="desc" className="col-sm-3 col-form-label">
                      Company Name *
                    </label>
                    <div className="col-sm-3">
                      <input
                        type="hidden"
                        className="form-control"
                        name="customer_id"
                        id="customer_id"
                      />
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="edit_name"
                      />
                      <span
                        id="edit_name_error"
                        style={{ color: "red", display: "none" }}
                      >
                        Please enter name
                      </span>
                    </div>
                    <label
                      htmlFor="compType"
                      className="col-sm-3 col-form-label"
                    >
                      Contact Person Name *
                    </label>
                    <div className="col-sm-3">
                      <input
                        type="text"
                        className="form-control"
                        name="contact_person_name"
                        id="edit_contact_person_name"
                      />
                      <span
                        id="edit_contact_person_name_error"
                        style={{ color: "red", display: "none" }}
                      >
                        Please enter contact person name
                      </span>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label
                      htmlFor="displayName"
                      className="col-sm-3 col-form-label"
                    >
                      Contact Email
                    </label>
                    <div className="col-sm-3">
                      <input
                        type="text"
                        className="form-control"
                        name="contact_person_email"
                        id="edit_contact_person_email"
                      />
                    </div>
                    <label
                      htmlFor="displayName"
                      className="col-sm-3 col-form-label"
                    >
                      Mobile
                    </label>
                    <div className="col-sm-3">
                      <input
                        maxLength={10}
                        type="text"
                        className="form-control"
                        name="contact_person_mobile"
                        id="edit_contact_person_mobile"
                      />
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label
                      htmlFor="displayName"
                      className="col-sm-3 col-form-label"
                    >
                      Currency *
                    </label>
                    <div className="col-sm-3">
                      <select
                        name="currency"
                        id="edit_currency"
                        className="form-select orgselect"
                        onChange={getBankByCurrency}
                        required=""
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Select Currency
                        </option>
                        <option value="GBP">GBP</option>
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                      </select>
                    </div>
                    <label
                      htmlFor="displayName"
                      className="col-sm-3 col-form-label"
                    >
                      Country *
                    </label>
                    <div className="col-sm-3">
                      <input
                        type="text"
                        className="form-control"
                        name="country"
                        id="edit_country"
                      />
                      <span
                        id="edit_country_error"
                        style={{ color: "red", display: "none" }}
                      >
                        Please select a country
                      </span>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="bank" className="col-sm-3 col-form-label">
                      Bank *
                    </label>
                    <div className="col-sm-3">
                      <select
                        name="bank"
                        id="bankEdit"
                        className="form-select orgselect"
                        required=""
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Select Bank
                        </option>
                        {bankData.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.bank_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-3">
                      <div className="radio-tbl">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            name="credit_facility"
                            id="edit_credit_facility"
                            onChange={displayInputsForCreditFacility}
                          />
                          <span className="default"></span>
                        </label>
                        <span>Credit Facility</span>
                      </div>
                    </div>
                    {showCreditFields && (
                      <div className="col-sm-3">
                        <input
                          type="text"
                          className="form-control"
                          name="credit_limit"
                          id="edit_credit_limit"
                          placeholder="Credit Limit Amount"
                        />
                      </div>
                    )}
                  </div>

                  {showCreditFields && (
                    <div className="col-sm-3">
                      <input
                        type="text"
                        className="form-control"
                        name="credit_days"
                        id="edit_credit_days"
                        placeholder="Credit Limit Day wise"
                      />
                    </div>
                  )}

                  <div className="row mb-3 padd-l-13px">
                    <label
                      htmlFor="displayName"
                      className="col-sm-3 col-form-label"
                    >
                      Status
                    </label>
                    <div className="col-sm-3">
                      <label className="switch">
                        <input
                          className="switch-input"
                          type="checkbox"
                          name="status"
                          id="edit_status"
                        />
                        <span
                          className="switch-label"
                          data-on="Active"
                          data-off="Inactive"
                        ></span>{" "}
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>

                  <h4>Billing Address </h4>
                  <div className="row mb-3 padd-l-13px">
                    <label
                      htmlFor="displayName"
                      className="col-sm-3 col-form-label"
                    >
                      Address Line 1 *
                    </label>
                    <div className="col-sm-3">
                      <input
                        type="text"
                        className="form-control"
                        name="b_address1"
                        id="edit_b_address1"
                      />
                      <span
                        id="edit_b_address1_error"
                        style={{ color: "red", display: "none" }}
                      >
                        Please enter address line 1
                      </span>
                    </div>
                    <label
                      htmlFor="displayName"
                      className="col-sm-3 col-form-label"
                    >
                      City *
                    </label>
                    <div className="col-sm-3">
                      <input
                        type="text"
                        className="form-control"
                        name="b_city"
                        id="edit_b_city"
                      />
                      <span
                        id="edit_b_city_error"
                        style={{ color: "red", display: "none" }}
                      >
                        Please enter city
                      </span>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label
                      htmlFor="displayName"
                      className="col-sm-3 col-form-label"
                    >
                      Address Line 2 *
                    </label>
                    <div className="col-sm-3">
                      <input
                        type="text"
                        className="form-control"
                        name="b_address2"
                        id="edit_b_address2"
                      />
                      <span
                        id="edit_b_address2_error"
                        style={{ color: "red", display: "none" }}
                      >
                        Please enter address line 2
                      </span>
                    </div>
                    <label
                      htmlFor="displayName"
                      className="col-sm-3 col-form-label"
                    >
                      Country *
                    </label>
                    <div className="col-sm-3">
                      <input
                        type="text"
                        className="form-control"
                        name="b_country"
                        id="edit_b_country"
                      />
                      <span
                        id="edit_b_country_error"
                        style={{ color: "red", display: "none" }}
                      >
                        Please enter country
                      </span>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label
                      htmlFor="displayName"
                      className="col-sm-3 col-form-label"
                    >
                      County *
                    </label>
                    <div className="col-sm-3">
                      <input
                        type="text"
                        className="form-control"
                        name="b_county"
                        id="edit_b_county"
                      />
                      <span
                        id="edit_b_county_error"
                        style={{ color: "red", display: "none" }}
                      >
                        Please enter county
                      </span>
                    </div>
                    <label
                      htmlFor="displayName"
                      className="col-sm-3 col-form-label"
                    >
                      Post Code *
                    </label>
                    <div className="col-sm-3">
                      <input
                        type="text"
                        className="form-control"
                        name="b_postcode"
                        id="edit_b_postcode"
                      />
                      <span
                        id="edit_b_postcode_error"
                        style={{ color: "red", display: "none" }}
                      >
                        Please enter postcode
                      </span>
                    </div>
                  </div>

                  <div>
                    <div id="deliveryAddress">
                      {editDeliveryAddresses.map((address, index) => (
                        <div className="address-block" key={index}>
                          <h4>
                            Delivery Address{" "}
                            <div style={{ float: "right" }}>
                              <input
                                type="radio"
                                className="checkDefaultEdit"
                                name="defaultDelivery[]"
                                value={index}
                                defaultChecked={address.defaultDelivery === '1'}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`gridRadios${index}`}
                              >
                                {" "}
                                Default{" "}
                              </label>
                              <Link
                                to="#"
                                onClick={() => removeEditAddress(index)}
                              >
                                <i className="bi bi-x"></i>
                              </Link>
                            </div>
                          </h4>
                          <input
                                type="hidden"
                                name="id[]"
                                id={`d_addr1_${index}`}
                                defaultValue={address.id ? address.id : 0}
                              />
                          <div className="row mb-3 padd-l-13px">
                            <label
                              htmlFor={`d_addr1_${index}`}
                              className="col-sm-3 col-form-label"
                            >
                              Address Line 1 *
                            </label>
                            <div className="col-sm-3">
                              <input
                                type="text"
                                className="form-control"
                                name="d_addr1[]"
                                id={`d_addr1_${index}`}
                                defaultValue={address.address_line1}
                              />
                            </div>
                            <label
                              htmlFor={`d_city_${index}`}
                              className="col-sm-3 col-form-label"
                            >
                              City *
                            </label>
                            <div className="col-sm-3">
                              <input
                                type="text"
                                className="form-control"
                                name="d_city[]"
                                id={`d_city_${index}`}
                                defaultValue={address.city}
                              />
                            </div>
                          </div>
                          <div className="row mb-3 padd-l-13px">
                            <label
                              htmlFor={`d_addr2_${index}`}
                              className="col-sm-3 col-form-label"
                            >
                              Address Line 2 *
                            </label>
                            <div className="col-sm-3">
                              <input
                                type="text"
                                className="form-control"
                                name="d_addr2[]"
                                id={`d_addr2_${index}`}
                                defaultValue={address.address_line2}
                              />
                            </div>
                            <label
                              htmlFor={`d_country_${index}`}
                              className="col-sm-3 col-form-label"
                            >
                              Country *
                            </label>
                            <div className="col-sm-3">
                              <input
                                type="text"
                                className="form-control"
                                name="d_country[]"
                                id={`d_country_${index}`}
                                defaultValue={address.country}
                              />
                            </div>
                          </div>
                          <div className="row mb-3 padd-l-13px">
                            <label
                              htmlFor={`d_county_${index}`}
                              className="col-sm-3 col-form-label"
                            >
                              County *
                            </label>
                            <div className="col-sm-3">
                              <input
                                type="text"
                                className="form-control"
                                name="d_county[]"
                                id={`d_county_${index}`}
                                defaultValue={address.county}
                              />
                            </div>
                            <label
                              htmlFor={`d_postcode_${index}`}
                              className="col-sm-3 col-form-label"
                            >
                              Post Code *
                            </label>
                            <div className="col-sm-3">
                              <input
                                type="text"
                                className="form-control"
                                name="d_postcode[]"
                                id={`d_postcode_${index}`}
                                defaultValue={address.post_code}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-12">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm add-adressbtn"
                          onClick={editNewAddress}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i> Add
                          Delivery Address
                        </button>
                      </div>
                    </div>
                  </div>
                  <div id="fileUploadsContainer"></div>
                  <hr />
                  <div className="row padd-l-13px">
                    <div className="col-sm-12">
                      <Link
                        to="#"
                        className="popup-innerbtn-common right"
                        onClick={submitUpdateCustomerForm}
                      >
                        {" "}
                        Save{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* View Brand Modal */}
      <div className="modal fade" id="view_customer" tabIndex="-1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">View Customer</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={emptyCusomerAddModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="viewcustomer-area">
                <form id="view_customer_form">
                  <div className="row">
                    <div className="col-6 col-lg-6 col-sm-6">&nbsp;</div>
                    <div className="col-6 col-lg-6 col-sm-6">
                      <div className="row">
                        <label htmlFor="inputText" className="col-sm-6">
                          &nbsp;
                        </label>
                        <div className="col-sm-6">
                          <h4 id="customer_status"></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="devider" />
                  <div className="row">
                    <div className="col-6 col-lg-6 col-sm-6">
                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          Customer ID
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="view_customerId"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          Contact Person Name
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="view_contact_person_name"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          VAT
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="view_vat"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-sm-6">
                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          Company Name
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="view_comapny_name"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          Email
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="view_email"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          Mobile
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="view_mobile"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 col-lg-6 col-sm-6">
                      <h3>
                        <span>Invoice Address </span>{" "}
                      </h3>

                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          Address Line 1
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="view_addr1"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          Address Line 2
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="view_addr2"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          City
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="view_city"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          Country
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="view_country"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          Post Code
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="view_postcode"
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-lg-6 col-sm-6">
                      <h3>
                        <span>Billing Address</span>{" "}
                        <div className="slect-area">
                          <div class="dropdown">
                            <button
                              class="ddbtn dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Select Address
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              {viewDeliveryAddresses.map((item, index) => (
                                <li
                                  key={item.id}
                                  className={
                                    item.defaultDelivery === "1"
                                      ? "defaultaddress"
                                      : ""
                                  }
                                >
                                  {item.defaultDelivery === "1" && (
                                    <div>
                                      <span class="badge bg-warning text-dark">
                                        Default
                                      </span>
                                    </div>
                                  )}

                                  <Link
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => getAddressById(item.id)}
                                  >
                                    <span>Address Line 1 - </span>{" "}
                                    {item.address_line1}
                                    <br />
                                    <span>Address Line 2 - </span>{" "}
                                    {item.address_line2}
                                    <br />
                                    <span>City - </span> {item.city}
                                    <br />
                                    <span>Country - </span> {item.country}
                                    <br />
                                    <span>Post Code - </span> {item.post_code}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </h3>
                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          {" "}
                          Address Line 1{" "}
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="view_billing_adr1"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          Address Line 2
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="view_billing_adr2"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          {" "}
                          City{" "}
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="view_billing_city"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          {" "}
                          Country{" "}
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="view_billing_country"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          {" "}
                          Post Code{" "}
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="view_billing_postcode"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 col-lg-12 col-sm-12">
                      <h3>
                        <span>Financial</span>{" "}
                        <a href="#" className="viewledger">
                          View Ledger
                        </a>
                      </h3>
                    </div>

                    <div className="col-6 col-lg-6 col-sm-6">
                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          {" "}
                          Balance{" "}
                        </label>
                        <div className="col-sm-6">
                          <input type="text" className="form-control" />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          {" "}
                          Credit Limit{" "}
                        </label>
                        <div className="col-sm-6">
                          <input type="text" className="form-control" />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          {" "}
                          Avg Days{" "}
                        </label>
                        <div className="col-sm-6">
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-sm-6">
                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          Currency
                        </label>
                        <div className="col-sm-6">
                          <input className="form-control" name="fCurrency" />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          {" "}
                          New Date{" "}
                        </label>
                        <div className="col-sm-6">
                          <input type="date" className="form-control" />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          {" "}
                          Last Transaction{" "}
                        </label>
                        <div className="col-sm-6">
                          <input type="date" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="viewcustomer-tbl-area">
                  <h5>Last 12 Month Turnover</h5>
                  <table className="table table-bordered ">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>Aug</th>
                        <th>Sep</th>
                        <th>Oct</th>
                        <th>Nov</th>
                        <th>Dec</th>
                        <th>Jan</th>
                        <th>Feb</th>
                        <th>Mar</th>
                        <th>Apr</th>
                        <th>May</th>
                        <th>Jun</th>
                        <th>Jul</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>T/O</td>
                        <td>13010</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>3244</td>
                        <td>0</td>
                        <td>2343</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              {" "}
              <a href="#">
                <i className="fa fa-home"></i>
              </a>{" "}
              &gt; <a href="dashboard.html">Dashboard</a> &gt;{" "}
              <span className="last-crumb">Customer List</span>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="dashboard">
                <div className="dashboard">
                  <div class="topsearch-area">
                    <div class="row">
                      <div class="col-12 col-lg-4 col-sm-4">
                        <div class="row">
                          <label
                            htmlFor="inputText"
                            className="col-sm-2 col-form-label"
                          >
                            Customer
                          </label>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              className="search-area form-control"
                              name="searchValue"
                              autoComplete="off"
                              id="searchValue"
                              onChange={initializeDataTable}
                              placeholder="Search Customer"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-8 col-sm-8">
                        <div style={{ float: "right" }}>
                          <Link
                            to="#"
                            className="create-catalogue-btn"
                            style={{ margin: "0px 5px" }}
                          >
                            Export to Excel
                          </Link>
                          <Link
                            to="#"
                            className="create-catalogue-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#add-customer"
                          >
                            Add New Customer
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", float: "left" }}>
                <div className="verical-scrl-wrapper cust-table" id="style-3">
                  <table
                    className="table table-bordered dataTable"
                    id="customerTable"
                    ref={tableRef}
                  >
                    <thead>
                      <tr>
                        <th>Cust ID</th>
                        <th>Company Name</th>
                        <th>Contact Person Name</th>
                        <th>Credit Status</th>
                        <th>Credit Due</th>
                        <th>Credit Overdue</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customerData.map((item, index) => (
                        <tr key={index}></tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Customer;
