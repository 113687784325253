import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import axios from 'axios';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { format } from 'date-fns';
import generateCollectionNotePDF from './CollectionNotePdf';

const SalesCollectionNote = () => {
    const { id } = useParams();
    const accessToken = localStorage.getItem('accessToken');

    const [salesInfo, setSalesInfo] = useState([]);
    const [salesitem, setSalesItem] = useState([]);
    const [totalSalesQty, setTotalSalesQty] = useState([]);
    const [totalPalletQty, setTotalPalletQty] = useState([]);

    const handleGenerateDeliveryNotePDF = (e, id) => {
	    generateCollectionNotePDF(id);
	}
    

    useEffect(() => {
	    fetchCollectionNote();
	 }, []);

    const fetchCollectionNote = async () => {
	    
	    const  formData = new FormData();
	    formData.set('sale_id', id);
	    const response = await axios.post(`${config.api_url}/ws-get-sales-by-id`, formData, {
	       headers: {
	          authorization: `Bearer ${accessToken}`,
	        }
	    }); 
	    const data = await response.data;
	    setSalesInfo(data.sale);
	    setSalesItem(data.product);
	    console.log(data);
	    let sQty = data.product.reduce((acc, product) => acc + parseInt(product.salesQty), 0);
	    let pQty = data.product.reduce((acc, product) => acc + parseInt(product.pallet_qty), 0);
	    setTotalSalesQty(sQty);
	    setTotalPalletQty(pQty);
    };

    return(
    	<>
    		<Header />
    		<div className="breadcrumbs">
        		<div className="container-fluid">
        			<div className="row">
        				<div className="col-12 col-lg-12 col-sm-12">
        					<a href="#"><i className="fa fa-home"></i></a> &gt; <a href="dashboard">Dashboard</a> &gt; <span className="last-crumb">Collection Note </span>
        				</div>
        			</div>
        		</div>
        	</div>

        	<div className="row">
        		<div className="col-12">
        			<div className="show-order-area dashboard">
        				<div className="sales-view-area" style={{ width: '100%', border: 'none' }}>
        					<div className="row">
        						<div className="col-7">
        							
        							<button onClick={(e) => handleGenerateDeliveryNotePDF(e, id)}>
									  Generate PDF
									</button>
        						</div>
        					</div>

        					<div className="row page-header" style={{ paddingTop: '10px' }}>
        						<div className="col-4">
        							<h5>NBC</h5>
        							<p>Unit 6 Abenglen Industrial Estate<br />Middlesex, London UB3 1AT<br />United Kingdom</p>
        						</div>
	        					<div className="col-4">
		        					<ul>
								        <li><b>Co. Reg. No :</b> 7298396</li>
								        <li><span>VAT Reg. No.:</span> GB994533184</li>
								        <li><span>Tel :</span> 44-208 329 3800</li>
		        					</ul>
	        					</div>
        					</div>

        					<div className="row page-header" style={{ paddingTop: '10px' }}>
        						<div className="col-4">
        							<ul>
								        <li><b>Collection Address : </b> {salesInfo.address_line1} {salesInfo.address_line2 ? `, <br />${salesInfo.address_line2}` : ''}</li>
								        <li><span>{salesInfo.city}</span></li>
								        <li><span>{salesInfo.post_code}</span></li>
								        <li><span>{salesInfo.country} </span></li>

        							</ul>
        						</div>
        						<div className="col-4">
						        	<ul>
								        <li><b>Customer Ref :   </b> </li>
								        <li><span>Booking Ref : {salesInfo.booking_ref} </span> </li>
								        <li><span>Booking Time : {salesInfo.order_date}</span> </li>
								        <li><span>Delivery Date : {salesInfo.delivery_date}</span> </li>
								        <li><span>Delivery Note : {salesInfo.notes}</span> </li>
								        <li><span>SO# :</span> </li>
						        	</ul>
						        </div>
						        

        					</div>

        					<div className="sales-view-table">
						       	<table className="table table-bordered" id="purchaseProforma">
								    <thead>
								        <tr>
								            <th style={{ backgroundColor: '#eee9e9' }}>Line No</th>
								            <th style={{ backgroundColor: '#eee9e9' }}>SKU Code</th>
								            <th style={{ backgroundColor: '#eee9e9' }}>Product Description</th>
								            <th style={{ backgroundColor: '#eee9e9' }}>Pack</th>
								            <th style={{ backgroundColor: '#eee9e9' }}>Size</th>
								            <th style={{ backgroundColor: '#eee9e9' }}>Unit Barcode</th>
								            <th style={{ backgroundColor: '#eee9e9' }}>Case Qty</th>
								            <th style={{ backgroundColor: '#eee9e9' }}>Pallet Qty</th>

								            
								        </tr>
								    </thead>
						   			<tbody>

							            {salesitem.map((item, index) => (
						                    <tr key={item.id}>
						                        <td>{index + 1}</td>
						                        <td>{item.code}</td>
						                        <td>{item.product_desc}</td>
						                        <td>{item.pack}</td>
						                        <td>{item.size}</td>
						                        <td>{item.unit_barcode}</td>
						                        <td>{item.salesQty}</td>
						                        <td>{item.pallet_qty}</td>
						                    </tr>
						                ))}
						   			</tbody>
						   			<tfoot>
									    <tr>
									        <td colSpan={4} style={{ textAlign: 'right', fontWeight: 'bold' }}>Total:</td>

									        <td colSpan={2}><span></span></td>
									        

									        <td><span>{totalSalesQty}</span></td>
									        <td><span>{totalPalletQty}</span></td>
									    </tr>
									</tfoot>
						   		</table>
						   	</div>

						   	<div className="row page-header" style={{ paddingTop: '10px' }}>
        						<div className="col-7">
        							<ul>
								        <li><span>Notes : </span>  Collection on {salesInfo.delivery_date}</li>
								        <li><span>Ref : </span> {salesInfo.job_ref}</li>
								        <li></li>

        							</ul>
        						</div>

        						<div className="col-2">

        						</div>
        						<div className="col-3">
							        <ul>
								        <li><span>Gross Weight: {salesInfo.gross_weight} KGS</span></li>
								        <li><span>CBM: {salesInfo.cube} M3</span></li>
								        <li><span>Net weight: {salesInfo.net_weight} KGS</span></li>
							        </ul>
        						</div>

        					</div>

        					<div className="row page-header" style={{ paddingTop: '10px' }}>
        						<div className="col-md-1">
        						</div>
        						<div className="col-md-3">
        							<table class="table table-bordered">
    
								    	<tbody>
									      	<tr>
									        	<td style={{padding: '5px 5px', width: '60%'}}>CHEP Standard</td>
									        	<td style={{padding: '5px 5px'}} ></td>
									        </tr>
								      		<tr>
									        	<td style={{padding: '5px 5px', width: '60%'}} >LPR Standard</td>
									        	<td style={{padding: '5px 5px'}} ></td>
									      	</tr>
								      		<tr>
								        		<td style={{padding: '5px 5px', width: '60%'}} >IPP Standard</td>
								        		<td style={{padding: '5px 5px'}} ></td>
								      		</tr>
								      		<tr>
								        		<td style={{padding: '5px 5px', width: '60%'}} >EHEP Euro</td>
								        		<td style={{padding: '5px 5px'}} ></td>
								      		</tr>
								      		<tr>
								        		<td style={{padding: '5px 5px', width: '60%'}} >Others</td>
								        		<td style={{padding: '5px 5px'}} ></td>
								      		</tr>
								    	</tbody>
								  	</table>
        						</div>
        						<div className="col-md-3">
						        	<label><b>Driver</b></label>
        							<p>Loaded in good condition and correct pellet count</p>
        							<p style={{minHeight: '8px'}}></p>
        							<p>Name : _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ </p>
        							<p style={{minHeight: '25px'}}></p>
        							
        							<p>Sign : _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ </p>
        							<p></p>
						        </div>
						        
						        <div className="col-md-4">
        							<label><b>Warehouse</b></label>
        							<p>Visual inspection of pallets and product carried out</p>
        							<p style={{minHeight: '8px'}}></p>
        							<p>Name : _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ </p>
        							<p style={{minHeight: '25px'}}></p>
        							<p>Sign : _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ </p>
        						</div>

        					</div>


        				</div>
        			</div>
        		</div>
        	</div>


    		<footer className="text-center mt-4">
        		<p className="lh-base">
          			<strong>Subject to NBC terms and conditions of sale, a copy of which is available on request.</strong><br />
          			<span>NBC (No. 7298396)</span>
        		</p>
        		<p>
          			Reservation of Title. The property in and the title to all goods sold by NBC shall not pass to the buyer until all monies due and owing have been paid in full
        		</p>
      		</footer>
    	</>

    );

}
export default SalesCollectionNote;