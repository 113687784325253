import React, { useState, useEffect, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import ViewSupplier from "../Master/ViewSupplier";
import SupplierSearch from "../Purchase/SupplierSearch";
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-bs5';
import { DateRangePicker } from 'rsuite';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
// import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';


const PurchaseLedger = () => {
  const [reportrange, setReportrange] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchValue, setSeachValue] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [searchSupplier, setSearchSupplier] = useState('');
  const [purchaseLedgerList, setPurchaseLedgerList] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setFromDate(format(start, 'yyyy-MM-dd'));
      setToDate(format(end, 'yyyy-MM-dd'));
      setReportrange(`${format(start, 'MM/dd/yyyy')} - ${format(end, 'MM/dd/yyyy')}`);
    }
  };

  const fetchData = async () => {
    const accessToken = localStorage.getItem('accessToken');
    
    if (!accessToken) {
      window.location.href = '/login';
      return;
    }

    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('searchValue', searchValue);
    formData.append('selectedType', selectedType);
    formData.append('supplier', searchSupplier);

    const apiUrl = `${config.api_url}/ws-purchase-ledger`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch purchase data');
      }

      const data = await response.json();
      if(data.success)
      {
        setPurchaseLedgerList(data.purchase_ledger);
      }else
      {
         setPurchaseLedgerList(data.purchase_ledger);
      }

    } catch (error) {
      console.error('Error fetching purchase data:', error);
    }
  }; 

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, searchValue, selectedType, searchSupplier, reportrange]);  

  return (
    <div>
    <Header />
    <ViewSupplier />
    {/* <SupplierSearch /> */}
    <div className="breadcrumbs">
    <div className="container-fluid">
    <div className="row">
    <div className="col-12 col-lg-12 col-sm-12"> <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="/Dashboard">Dashboard</a> &gt; <span className="last-crumb">Purchase Ledger</span> </div>
    </div>
    </div>
    </div>
    <div className="main-container">
    <form id='new_sales_order'>
    <div className="container-fluid">
    <div className="row">
    <div className="col-12 col-lg-12 col-sm-12">
    <div className="show-order-area dashboard">
    <div className="dashform">
    <div className="topsearch-area">
    <div className="row">
    <div className="col-12 col-lg-3 col-sm-3">
    <div className="row">
    <label htmlFor="inputText" className="col-sm-6 col-form-label">P.O ID/Supplier/Bill No./Job Ref</label>
    <div className="col-sm-6">
    <input type="text" className="search-area form-control" placeholder="Search" name="product_name" id="product_name" onChange={(e)=>setSeachValue(e.target.value)} />
    </div>
    </div>
    </div>
    <div className="col-12 col-lg-1 col-sm-1">
    <select name="warehouse_id" id="warehouse_id" className="form-select" defaultValue='' onChange={(e)=>setSelectedType(e.target.value)}>
    <option value="" disabled>Type</option>
    <option value="all">All</option>
    <option value="inv">INV</option>
    <option value="credit">Credit</option>
    </select>
    </div>
    <div className="col-sm-2">
    <SupplierSearch  setSearchSupplier={setSearchSupplier} />
    </div>
    <div className="col-12 col-lg-6 col-sm-6">
    <div className="row">
    <label htmlFor="inputText" className="col-sm-3 col-form-label">Date Range</label>
    <div className="col-sm-7">

    <DateRangePicker
    value={[fromDate ? new Date(fromDate) : undefined, toDate ? new Date(toDate) : undefined]}
    onChange={handleDateChange}
    format="MM/dd/yyyy"
    /> 

    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div>
    <div className="verical-scrl-wrapper common-table" id="style-3">
    <table className="table table-bordered dataTable" id="supplierTable">
    <thead>
    <tr>
    <th>ID</th>
    <th>Raised On</th>
    <th>Type</th>
    <th>Bill No</th>
    <th>Job Ref</th>
    <th>P.O ID</th>
    <th>Supplier (ID)</th>
    <th>Value</th>
    <th>Balance</th>
    <th>Due Date</th>
    <th>Created By</th>
    <th>Status</th>
    <th>Action</th>
    </tr>
    </thead>
    <tbody>
      {Array.isArray(purchaseLedgerList) && purchaseLedgerList.length === 0 ? (
        <tr>
        <td colSpan="13" style={{ textAlign: 'center' }}>No data found</td>
      </tr>      
      ) : (
        Array.isArray(purchaseLedgerList) && purchaseLedgerList.map((row, index) => (
          <tr key={index}>
            <td><a href={`EditSupplierBill/${row.purchase_id}`}>{row.id}</a></td>
            <td>{row.raised_on}</td>
            <td>{row.type}</td>
            <td>{row.bill_no}</td>
            <td>{row.job_ref}</td>
            <td><a href={`PurchaseHistoryView/${row.purchase_id}`}>{row.reference_no}</a></td>
            <td>
              <a href="#" className="viewbtn" data-id={row.supplier_id} data-bs-toggle="modal" data-bs-target="#viewsupplier">
                {row.name}
              </a>
            </td>
            <td style={{ textAlign: 'right' }}>{row.value}</td>
            <td style={{ textAlign: 'right' }}>{row.balance}</td>
            <td>{row.due_date}</td>
            <td>{row.created_by}</td>
            <td>{row.status}</td>
            <td><Link to={`/PayBills/${row.purchase_id}`}>Pay</Link></td>
          </tr>
        ))
      )}
    </tbody>
      </table>
      </div>
      </div>
      <hr/>
      <h5>Aging (i) Requires supplier to be selected from the filter</h5>
      <div className="verical-scrl-wrapper common-table" id="style-3">
      <table className="table table-bordered dataTable" id="ledger2">
      <thead>
      <tr>
      <th>Balance</th>
      <th>UnAlloc</th>
      <th>0 to 1</th>
      <th>1 to 2</th>
      <th>2 to 3</th>
      <th>3 to 4</th>
      <th>4 plus</th>
      </tr>
      </thead>
      <tbody>
      <tr>
      <td>500</td>
      <td>0</td>
      <td>0</td>
      <td>500</td>
      <td>0</td>
      <td>0</td>
      <td>0</td>
      </tr> 
      </tbody>
      </table>
      </div>
      </div>
      </div>
      </div>
      </div>
      </form>
      </div>

      <Footer />
      </div>
      );
    };

    export default PurchaseLedger; 
