import React, { useState, useEffect } from "react";
import Header from "../Common_Files/Header";
import Footer from "../Common_Files/Footer";
import config from "../Common_Files/Config";
import generateDeliveryNotePDF from "./DeliveryNote";
import DeliveryView from "./DeliveryView";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import ViewCustomer from "../Master/ViewCustomer";
import $ from "jquery";
import "datatables.net-bs5";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
import jsPDF from 'jspdf';
import axios from 'axios';

const OrderHistory = () => {
  const [reportrange, setReportrange] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [orderHistory, setOrderHistory] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const accessToken = localStorage.getItem("accessToken");

  const [deliveryData, setDeliveryData] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const handlegenerateDeliveryNotePDF = (e, id) => {
    generateDeliveryNotePDF(id);
  };

  const handlegenerateDeliveryViewInv = (e, id) => {
    generateDeliveryView(id);
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setFromDate(format(start, "yyyy-MM-dd"));
      setToDate(format(end, "yyyy-MM-dd"));
      setReportrange(
        `${format(start, "MM/dd/yyyy")} - ${format(end, "MM/dd/yyyy")}`
      );
    }
  };


  const generateDeliveryView = async (id) => {
    const accessToken = localStorage.getItem('accessToken');
    const formData = new FormData();
    formData.set('sale_id', id);
    const apiUrl = `${config.api_url}/ws-get-sales-by-id`;
  
    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
      });
  
      const data = await response.data;
  
      const totalSalesQty = data.product.reduce((acc, item) => acc + parseInt(item.salesQty, 10), 0);
      const totalPalletQty = data.product.reduce((acc, item) => acc + parseInt(item.pallet_qty, 10), 0);
  
      const doc = new jsPDF('landscape'); // Set to landscape orientation
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
  
      // Margins and Y position initialization
      const margin = 10;
      let y = margin;
  
      // Define and render header and footer sections
      // (Your existing code for header and footer rendering goes here)
  
      // Render table
      const columns = [
        { title: 'Line No', dataKey: 'line_no', size: 8, bold: false },
        { title: 'SKU Code', dataKey: 'sku_code', size: 8, bold: false },
        { title: 'Product Description', dataKey: 'pdt_desc', size: 8, bold: false },
        { title: 'Pack', dataKey: 'pack', size: 8, bold: false },
        { title: 'Size', dataKey: 'size', size: 8, bold: false },
        { title: 'Unit Barcode', dataKey: 'unit_brcode', size: 8, bold: false },
        { title: 'Case Qty', dataKey: 'case_qty', size: 8, bold: false },
        { title: 'Pallet Qty', dataKey: 'pallete_qty', size: 8, bold: false },
      ];
  
      const tableBody = data.product.map((item, index) => [
        index + 1,
        item.code,
        item.product_desc,
        item.pack,
        item.size,
        item.unit_barcode,
        item.salesQty,
        item.pallet_qty,
      ]);
  
      // Add an empty row with totals
      tableBody.push([
        '', '', '', '', '', 'Total : ', totalSalesQty.toString(), totalPalletQty.toString(),
      ]);
  
      // Render the table
      doc.autoTable({
        startY: y,
        head: [columns.map(col => col.title)],
        body: tableBody,
        theme: 'grid',
        headStyles: {
          fillColor: '#f8f9fa',
          textColor: '#404040',
          size: '13px',
        },
        margin: { top: 10 },
      });
  
      // Position after table
      y = doc.autoTable.previous.finalY + 12;
  
      // Add footer sections
      // (Your existing code for adding footer sections goes here)
  
      // Output the PDF to a data URL
      const pdfDataUrl = doc.output('datauristring');
  
      // Open the PDF in a new window
      window.open(pdfDataUrl);
      
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  

  const fetchData = async () => {
    const formData = new FormData();
    formData.append("searchByFromdate", fromDate);
    formData.append("searchByTodate", toDate);
    formData.append("searchValue", selectedRole);
    formData.append("draw", draw);
    formData.append("start", start);
    formData.append("length", length);

    const apiUrl = `${config.api_url}/ws-get-sales-history`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch sales data");
      }

      const data = await response.json();
      setOrderHistory(data.data);
      $("#total_grand_total").text(data.total_grand_total);
      $("#total_grand_total1").text(data.total_grand_total1);
    } catch (error) {
      console.error("Error fetching sales data:", error);
    }
  };

  useEffect(() => {
    if (!accessToken) {
      window.location.href = '/login';
      return;
  }
    fetchData();
  }, [fromDate, toDate, selectedRole]);

  useEffect(() => {
    const table = $("#neworderTable1").DataTable({
      data: orderHistory,
      columns: [
        { data: "order_date", title: "Order Date" },
        {
            data: "reference_no",
            render: (data, type, row) => `
                <a class="applybtn view-invoice" href="/DeliveryInvView/${row.ids}"  data-id="${row.ids}" >${data}</a>
            `,
        },
        {
            data: "company_name",
            render: (data, type, row) => `
                <a class="viewbtn" href="#" data-id="${row.customer_id}" data-bs-toggle="modal" data-bs-target="#view_customer" title="View Customer">${data}</a>
            `,
        },
        { data: "order_amount", title: "Order Amount" },
        { data: "previous_due", title: "Previous Dues" },
        { data: "status", title: "Status" },
        {
            data: null,
            render: (data, type, row) => `
                <div class="btn-group">
                    <button type="button" class="action-btn-dd dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Action</button>
                    <ul class="dropdown-menu dropdown-menu-arrow">
                        <li><a class="applybtn update-customer" href="#" data-id="${row.ids}">INV Download</a></li>
                         <li><a class="applybtn view-invoice" href="/DeliveryInvView/${row.ids}"  data-id="${row.ids}" >View INVOICE</a></li>
                    </ul>
                </div>`,
        },
    ],
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: false,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
    });



  const fetchDeliveryData = async (id) => {
    const accessToken = localStorage.getItem('accessToken');
    const formData = new FormData();
    formData.set('sale_id', id);
    const apiUrl = `${config.api_url}/ws-get-sales-by-id`;

    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      setDeliveryData(response.data);
    } catch (error) {
      console.error('Error fetching delivery data:', error);
    } finally {
      setLoading(false);
    }
  };

    // Bind click event after table initialization
    $("#neworderTable1").on("click", ".applybtn.update-customer", function (e) {
      const id = $(this).data("id"); 
      handlegenerateDeliveryNotePDF(e, id);
    });

    $("#neworderTable1").on("click", ".applybtn.view-invoice", function (e) {
      const id = $(this).data("id"); 
      handlegenerateDeliveryViewInv(e, id);
    });

    return () => {
      table.destroy();
      $("#neworderTable1").off("click", ".applybtn.update-customer"); 
    };
  }, [orderHistory]);

  return (
    <div>
      <Header />
      <ViewCustomer />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <a href="#">
                <i className="fa fa-home"></i>
              </a>{" "}
              &gt; <a href="/Dashboard">Dashboard</a> &gt;{" "}
              <span className="last-crumb">Order History</span>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="show-order-area dashboard">
                <form onSubmit={handleSubmit} className="dashform">
                  <div className="topsearch-area d-flex align-items-center">
                    <div className="form-group d-flex align-items-center">
                      <label
                        htmlFor="dateRange"
                        className="col-sm-4 col-form-label mr-2"
                      >
                        Date Range
                      </label>
                      <DateRangePicker
                        value={[
                          fromDate ? new Date(fromDate) : undefined,
                          toDate ? new Date(toDate) : undefined,
                        ]}
                        onChange={handleDateChange}
                        format="MM-dd-yyyy"
                        className="mr-2"
                      />
                    </div>
                  </div>
                </form>
                <div className="verical-scrl-wrapper order-history-table"  id="style-3"  >
                  <table className="table table-bordered dataTable" id="neworderTable1" >
                    <thead>
                      <tr>
                        <th>Order Date</th>
                        <th>Order Id</th>
                        <th>Company Name</th>
                        <th>Order Amount</th>
                        <th>Previous Dues</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default OrderHistory;
