import React, { useState, useEffect, useRef } from "react";
import Header from "../Common_Files/Header";
import Footer from "../Common_Files/Footer";
import config from "../Common_Files/Config";
import ViewSupplier from "../Master/ViewSupplier";
import AddSupplierModal from "../Master/Add/AddSupplierModal";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
 

const Supplier = () => {
  const [supplierData, setSupplierData] = useState([]);
  const [editSupplier, setEditSupplier] = useState([]);
  const [vatData, setVatData] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const modalRef = useRef(null); // Reference to the modal
  const updateModalRef = useRef(null); // Reference to the modal
  const tableRef = useRef(null); // Reference to the table element
  const accessToken = localStorage.getItem('accessToken');

  const fetchData = async () => {
    if (!accessToken) {
      window.location.href = '/login';
      return;
  }
    const searchValue = $('#supplier_name_search').val();
    // alert(searchValue);
    const formData = new FormData();
    formData.append("draw", draw);
    formData.append("start", start);
    formData.append("length", length);
    formData.append("searchValue", searchValue);

    const apiUrl = `${config.api_url}/ws-supplier-list`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch new sales data");
      }

      const data = await response.json();
      setSupplierData(data.data);
    } catch (error) {
      console.error("Error fetching new sales data:", error);
    }
  };



  useEffect(() => {
    $("#supplierTable tbody").off("click", ".deductionbtn");
    // Attach the event listener
    $("#supplierTable tbody").on("click", ".deductionbtn", function () {
      $('#edit_name_error').hide();
      $('#edit_postcode_error').hide();
      $('#edit_country_error').hide();
      $('#edit_address1_error').hide();
      $('#edit_city_error').hide();
      $('#edit_bank_name_error').hide();
      $('#edit_iban_error').hide();
      $('#edit_sort_code_error').hide();
      $('#edit_bank_account_number_error').hide();

      const supplier_id = $(this).data("id");
      const formData = new FormData();
      formData.append("supplier_id", supplier_id);

      const fetchSupplierData = async () => {
        const apiUrl = `${config.api_url}/ws-supplier-list-by-id`;
        document.getElementById("editSupplierForm").reset();
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
            body: formData,
          });

          const data = await response.json();
          $("#supplier_id").val(data.supplier[0].id);
          $("#name").val(data.supplier[0].name);
          $("#postcode").val(data.supplier[0].postcode);
          $("#country").val(data.supplier[0].country);
          $("#vat").val(data.supplier[0].vat_id);
          $("#address1").val(data.supplier[0].address_line1);
          $("#address2").val(data.supplier[0].address_line2);
          $("#city").val(data.supplier[0].city);
          $("#currency").val(data.supplier[0].currency_id).change();
          $("#contact_person_name").val(data.supplier[0].contact_person_name);
          $("#contact_person_mobile").val(data.supplier[0].contact_person_mobile);
          $("#contact_person_email").val(data.supplier[0].contact_person_email);
          $("#bank_name").val(data.supplier[0].bank_name);
          $("#bank_account_number").val(data.supplier[0].bank_account_number);
          $("#sort_code").val(data.supplier[0].sort_code);
          $("#bank_bic").val(data.supplier[0].bank_bic);
          $("#branch_bic").val(data.supplier[0].branch_bic);
          $("#iban").val(data.supplier[0].iban);

          if (data.supplier[0].payment_type == "1") {
            $("#direct_debit_type").prop("checked", true);
            $("#direct_debit").val(data.supplier[0].direct_debit);
          } else if (data.supplier[0].payment_type == "2") {
            $("#no_of_days_from_inv_type").prop("checked", true);
            $("#no_of_days_from_inv").val(data.supplier[0].no_of_days_from_inv);
          } else if (data.supplier[0].payment_type == "3") {
            $("#credit_limit_type").prop("checked", true);
            $("#credit_limit").val(data.supplier[0].credit_limit);
          }
          else if (data.supplier[0].payment_type == "4") {
            $("#at_sight").prop("checked", true);
          }

          if (data.supplier[0].status == "1") {
            $("#status").prop("checked", true);
          }

        } catch (error) {
          console.error("Error fetching supplier data:", error);
        }
      };
      fetchSupplierData();
    });

    return () => {
      $("#supplierTable tbody").off("click", ".deductionbtn");
    };
  }, []);

  useEffect(() => {
    fetchData();
    fetchVatData();
  }, []);

  useEffect(() => {
    const table = $("#supplierTable").DataTable({
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: false,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      data: supplierData,
      columnDefs: [
        { orderable: false, targets: '_all' }
      ],

      columns: [
        { data: "code", width: "5%" },
        { data: "name", },
        { data: "postcode", width: "7%" },
        { data: "contact_person_name", },
        { data: "contact_person_mobile", width: "7%" },
        { data: "contact_person_email", width: "12%" },
        { data: "sort_code", width: "7%" },
        {
          data: "status",
           width: "7%",
          render: function (data, type, row) {
            if (data == 0) {
              return "Inactive";
            } else if (data == 1) {
              return "Active";
            }
          },
        },
        {
          data: null,
          title: 'Action',
          width: "7%",
          render: (data, type, row) => `
              <a href="#" class="deductionbtn" data-id="${row.id}" title="Edit Supplier" data-bs-toggle="modal" data-bs-target="#edit_supplier">
                  <i class="fa fa-edit"></i> 
              </a>
              <a href="#" class="viewbtn" data-id="${row.id}" title="View Supplier" data-bs-toggle="modal" data-bs-target="#viewsupplier">
                  <i class="fa fa-eye"></i> 
              </a>
              <a href="#" class="applybtn" title="Download Product List" ><i class="fa fa-download"></i></a>`
        },
      ],
      
    });
    return () => {
      table.destroy();
    };
  }, [supplierData]);

  const submitNewSupplierForm = async (e) => {
    e.preventDefault();
    try {
      const formElement = document.getElementById("addSupplierForm");
      const formData = new FormData(formElement);
      formData.set("status", formData.get("status") === "on" ? 1 : 0);

      const response = await axios.post(
        `${config.api_url}/ws-add-supplier`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          },
        }
      );

      if (response.data.success) {
        Swal.fire({
          title: "Success!",
          text: "Supplier added successfully.",
          icon: "success",
          timer: 1500, // Automatically close after 1500ms
          showConfirmButton: false,
        })
          .then(() => {
            // Hide the modal after SweetAlert completes
            if (modalRef.current) {
              const bsModal = window.bootstrap.Modal.getInstance(
                modalRef.current
              );
              if (bsModal) bsModal.hide();
            }

            fetchData();

            document.getElementById("addSupplierForm").reset();
            // Refresh categories after adding a new one
          })
          .catch((error) => {
            console.error("Error refreshing supplier:", error);
          });
      } else {
        Swal.fire({
          title: "Error!",
          text:
            response.data.message ||
            "An error occurred while adding a supplier.",
          icon: "error",
          timer: 1500, // Automatically close after 1500ms
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Request Error:", error);
      Swal.fire({
        title: "Error!",
        text: "An error occurred while adding a supplier.",
        icon: "error",
        timer: 1500, // Automatically close after 1500ms
        showConfirmButton: false,
      });
    }
  };

  const fetchVatData = async () => {
    const apiUrl = `${config.api_url}/ws-get-vat`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
      });
      const data = await response.json();
      setVatData(data.vat);
    } catch (error) {
      console.error("Error fetching vat data:", error);
    }
  };

  const updateSupplierForm = async () => {
    var name = $('#name').val();
    var postcode = $('#postcode').val();
    var country = $('#country').val();
    var address1 = $('#address1').val();
    var city = $('#city').val();
    var bank_name = $('#bank_name').val();
    var iban = $('#iban').val();
    var sort_code = $('#sort_code').val();
    var bank_account_number = $('#bank_account_number').val();

    var return_check = 0;

    if (name === '') {
        $('#edit_name_error').show();
        return_check = 1;
    } else {
        $('#edit_name_error').hide();
    }

    if (postcode === '') {
        $('#edit_postcode_error').show();
        return_check = 1;
    } else {
        $('#edit_postcode_error').hide();
    }

    if (country === '') {
        $('#edit_country_error').show();
        return_check = 1;
    } else {
        $('#edit_country_error').hide();
    }

    if (address1 === '') {
        $('#edit_address1_error').show();
        return_check = 1;
    } else {
        $('#edit_address1_error').hide();
    }

    if (city === '') {
        $('#edit_city_error').show();
        return_check = 1;
    } else {
        $('#edit_city_error').hide();
    }

    if (bank_name === '') {
        $('#edit_bank_name_error').show();
        return_check = 1;
    } else {
        $('#edit_bank_name_error').hide();
    }

    if (iban === '') {
        $('#edit_iban_error').show();
        return_check = 1;
    } else {
        $('#edit_iban_error').hide();
    }

    if (sort_code === '') {
        $('#edit_sort_code_error').show();
        return_check = 1;
    } else {
        $('#edit_sort_code_error').hide();
    }

    if (bank_account_number === '') {
        $('#edit_bank_account_number_error').show();
        return_check = 1;
    } else {
        $('#edit_bank_account_number_error').hide();
    }

    if (return_check == 1) {
        return;
    }



    const formElement = document.getElementById("editSupplierForm");
    const formData = new FormData(formElement);
    formData.set("status", formData.get("status") === "on" ? 1 : 0);

    try {
      const response = await fetch(`${config.api_url}/ws-update-supplier`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        body: formData,
      });

      const result = await response.json();

      if (result.success) {
        Swal.fire({
          title: "Success!",
          text: "Supplier updated successfully.",
          icon: "success",
          timer: 1500, // Automatically close after 1500ms
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: "Error!",
          text:
            response.data.message ||
            "An error occurred while updating a supplier.",
          icon: "error",
          timer: 1500, // Automatically close after 1500ms
          showConfirmButton: false,
        });
      }
      if (updateModalRef.current) {
        const bsModal = window.bootstrap.Modal.getInstance(updateModalRef.current);
        if (bsModal) bsModal.hide();
      }

      fetchData();
      document.getElementById("editSupplierForm").reset();

    } catch (error) {
      console.error("Error updating product:", error);
    }
  };


  return (
    <div>

      <div className="modal fade" id="edit_supplier" tabIndex="-1" ref={updateModalRef} >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Supplier</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="add-product-popup">
                <form id="editSupplierForm">
                  <h4>Supplier Details</h4>
                  <div className="row mb-3 padd-l-13px">
                    <input type="hidden" className="form-control" name="supplier_id" id="supplier_id" />
                    <label htmlFor="desc" className="col-sm-3 col-form-label"> Supplier Name * </label>
                    <div className="col-sm-3">
                      <input type="text" className="form-control" name="name" id="name" />
                      <span id="edit_name_error" style={{ color: 'red', display: 'none' }}> Please enter name </span>
                    </div>
                    <label htmlFor="compType" className="col-sm-3 col-form-label" > Address Line 1 * </label>
                    <div className="col-sm-3">
                      <input type="text" className="form-control" name="address1" id="address1" />
                      <span id="edit_address1_error" style={{ color: 'red', display: 'none' }}> Please enter address line </span>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="displayName" className="col-sm-3 col-form-label" > Postcode * </label>
                    <div className="col-sm-3">
                      <input type="text" className="form-control" name="postcode" id="postcode" />
                      <span id="edit_postcode_error" style={{ color: 'red', display: 'none' }}> Please enter postcode </span>
                    </div>
                    <label htmlFor="compType" className="col-sm-3 col-form-label" > Address Line 2 </label>
                    <div className="col-sm-3">
                      <input type="text" className="form-control" name="address2" id="address2" />
                    </div>

                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="displayName" className="col-sm-3 col-form-label" > Country * </label>
                    <div className="col-sm-3">
                      <input type="text" className="form-control" name="country" id="country" />
                      <span id="edit_country_error" style={{ color: 'red', display: 'none' }}> Please enter country </span>
                    </div>

                    <label htmlFor="displayName" className="col-sm-3 col-form-label" > City * </label>
                    <div className="col-sm-3">
                      <input type="text" className="form-control" name="city" id="city" />
                      <span id="edit_city_error" style={{ color: 'red', display: 'none' }}> Please enter city </span>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="displayName" className="col-sm-3 col-form-label"  > VAT Number  </label>
                    <div className="col-sm-3">
                      <input type="text" className="form-control" name="vat" id="vat" />
                    </div>

                    <label htmlFor="displayName" className="col-sm-3 col-form-label" > Status </label>
                    <div className="col-sm-3">
                      <label className="switch">
                        <input className="switch-input" type="checkbox" name="status" id="status" />
                        <span className="switch-label" data-on="Active" data-off="Inactive" ></span>{" "}
                        <span className="switch-handle"></span>
                      </label>
                    </div>

                  </div>

                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="displayName" className="col-sm-3 col-form-label"  > &nbsp;</label>
                    <div className="col-sm-3">
                      &nbsp;
                    </div>

                    <label htmlFor="displayName" className="col-sm-3 col-form-label"  >Currency * </label>
                    <div className="col-sm-3">
                      <select name="currency" id="currency" className="form-select select" >
                        <option value="GBP">GBP</option>
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                      </select>
                    </div>
                  </div>
                  <h4>Contact Person</h4>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="displayName" className="col-sm-3 col-form-label" >
                      Contact Person Name
                    </label>
                    <div className="col-sm-3">
                      <input type="text" className="form-control" name="contact_person_name" id="contact_person_name" />
                    </div>
                    <label htmlFor="displayName" className="col-sm-3 col-form-label" > Mobile </label>
                    <div className="col-sm-3">
                      <input type="text" className="form-control" name="contact_person_mobile" id="contact_person_mobile" />
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="displayName" className="col-sm-3 col-form-label" >
                      Email </label>
                    <div className="col-sm-3">
                      <input type="text" className="form-control" name="contact_person_email" id="contact_person_email" />
                    </div>
                  </div>
                  <h4>Banking Details</h4>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="displayName" className="col-sm-3 col-form-label"  >
                      Bank Name *
                    </label>
                    <div className="col-sm-3">
                      <input type="text" className="form-control" name="bank_name" id="bank_name" />
                      <span id="edit_bank_name_error" style={{ color: 'red', display: 'none' }}> Please enter bank name </span>
                    </div>
                    <label htmlFor="displayName" className="col-sm-3 col-form-label" >
                      Account Number *
                    </label>
                    <div className="col-sm-3">
                      <input type="text" className="form-control" name="bank_account_number" id="bank_account_number" />
                      <span id="edit_bank_account_number_error" style={{ color: 'red', display: 'none' }}> Please enter bank_account_number </span>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="displayName" className="col-sm-3 col-form-label"  >
                      IBAN *
                    </label>
                    <div className="col-sm-3">
                      <input type="text" className="form-control" name="iban" id="iban" />
                      <span id="edit_iban_error" style={{ color: 'red', display: 'none' }}> Please enter iban </span>
                    </div>
                    <label htmlFor="displayName" className="col-sm-3 col-form-label"  >
                      Sort Code *
                    </label>
                    <div className="col-sm-3">
                      <input type="text" className="form-control" name="sort_code" id="sort_code" />
                      <span id="edit_sort_code_error" style={{ color: 'red', display: 'none' }}> Please enter sort code </span>
                    </div>
                  </div>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="displayName" className="col-sm-3 col-form-label" >
                      Branch BIC
                    </label>
                    <div className="col-sm-3">
                      <input type="text" className="form-control" name="branch_bic" id="branch_bic" />
                    </div>
                    <label htmlFor="displayName" className="col-sm-3 col-form-label" >
                      Bank BIC
                    </label>
                    <div className="col-sm-3">
                      <input type="text" className="form-control" name="bank_bic" id="bank_bic" />
                    </div>
                  </div>
                  <h4>Payment Terms</h4>
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="displayName" className="col-sm-3 col-form-label" >
                      {" "}
                      Payment Type
                    </label>
                    <div className="col-sm-9">
                      <div className="paymenttypearea">
                        <ul>
                          <li>
                            <input
                              className=""
                              type="radio"
                              name="payment_type"
                              id="direct_debit_type"
                              value="1"
                              defaultChecked=""
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gridRadios1"
                              style={{ width: "170px" }}
                            >
                              {" "}
                              Direct Debit{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="direct_debit"
                              id="direct_debit"
                            />
                          </li>
                          <li>
                            <input
                              className=""
                              type="radio"
                              name="payment_type"
                              id="no_of_days_from_inv_type"
                              value="2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gridRadios1"
                              style={{ width: "170px" }}
                            >
                              {" "}
                              No. of days from the INV{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="no_of_days_from_inv"
                              id="no_of_days_from_inv"
                            />
                          </li>
                          <li>
                            <input
                              className=""
                              type="radio"
                              name="payment_type"
                              id="credit_limit_type"
                              value="3"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gridRadios1"
                              style={{ width: "170px" }}
                            >
                              {" "}
                              Credit Limit{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="credit_limit"
                              id="credit_limit"
                            />
                          </li>
                          <li>
                            <input
                              className=""
                              type="radio"
                              name="payment_type"
                              id="at_sight"
                            value="4"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gridRadios1"
                              style={{ width: "170px" }}
                            >
                              At Sight / On PFI
                            </label>
                            {/* <input
                              type="text"
                              className="form-control"
                              name="credit_limit" /> */}
                            </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row padd-l-13px">
                    <div className="col-sm-12">
                      <Link
                        to="#"
                        onClick={updateSupplierForm}
                        className="popup-innerbtn-common right"
                      >
                        Update
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Header />
      <ViewSupplier />
      <AddSupplierModal fetchData={fetchData} />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              {" "}
              <a href="#">
                <i className="fa fa-home"></i>
              </a>{" "}
              &gt; <a href="dashboard.html">Dashboard</a> &gt;{" "}
              <span className="last-crumb">Supplier List</span>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard">
                <div className="supplierlst-tbl">
                <div class="topsearch-area">
                  <div class="row">
                    <div class="col-12 col-lg-4 col-sm-4">
                      <div class="row">
                        <label htmlFor="inputText" className="col-sm-3 col-form-label"> Supplier</label>
                        <div class="col-sm-6">
                          <input type="text" className="search-area form-control" autoComplete='off' onChange={fetchData} placeholder="Search Supplier" name="supplier_name_search" id="supplier_name_search" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-8 col-sm-8">
                    <div style={{ float: "right", width: "auto" }}>
                      <a href="#" className="create-catalogue-btn" style={{ margin: "0 5px" }} > Export to Excel </a>
                      <a href="#" className="create-catalogue-btn" style={{ margin: "0 5px" }}  data-bs-toggle="modal"
                        data-bs-target="#add-supplier"> Add New Supplier </a>
                      </div>
                    </div>
                  </div>
                </div>
                  <div style={{ width: "100%", float: "left" }}>
                    <div className="verical-scrl-wrapper" id="style-3">
                      <table className="table table-bordered dataTable" id="supplierTable" >
                        <thead>
                          <tr>
                            <th>Sup ID</th>
                            <th>Name</th>
                            <th>Postcode </th>
                            <th>Contact Person Name</th>
                            <th>Mobile</th>
                            <th>Email</th>
                            <th>Sort Code</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Supplier;
