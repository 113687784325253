import React, { useState, useEffect, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import ViewProduct from '../Master/ViewProduct';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import { fetchCustomerData, fetchEditCustomerData, handleCustomerChange, fetchWarehouseData, handleProductAdd, handleProductAddNew, handleSalesProductAddNew, getDeliveryAddrById, handleSalesBatchChange, handleSalesBatchChangeEdit, setSalesBatchToProduct } from '../../services/NewSalesService';
import { fetchProductData, removeRow, handleInputChange, handleInputChangeInSalesNew, handleInputChangeIneditSales } from '../../services/NewPurchaseOrderService';

const NewSalesOrderEdit = () => {
  const accessToken = localStorage.getItem('accessToken');
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const [inputDeliveryAddresses, setInputDeliveryAddresses] = useState([]);
  const [deliveryAddrDropdown, setDeliveryAddrDropdown] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [invoiceAddress, setInvoiceAddress] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [warehouseData, setWarehouseData] = useState([]);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
  const [productData, setProductData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [stdGoods, setStdGoods] = useState(0);
  const [vatAmt, setVatAmt] = useState(0);
  const [zeroGoods, setZeroGoods] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [grossWeight, setGrossWeight] = useState(0);
  const [netWeight, setNetWeight] = useState(0);
  const [cube, setCube] = useState(0);
  const [grossProfit, setGrossProfit] = useState(0);
  const [grossProfitPercent, setGrossProfitPercent] = useState(0);
  const [productSO, setProductSO] = useState([]);
  const navigateToOrderHistory = useNavigate();
  const { id } = useParams();
  const [productBatch, setProductBatch] = useState([]);
  const [modalData, setModalData] = useState(null);
  const productBatchRef = useRef(null);
  const AssignBatchModal = useRef(null);
  const [selectedBatchId, setSelectedBatchId] = useState([]);
  const [selectedpurchaseId, setSelectedpurchaseId] = useState([]);

  const [salesInfo, setSalesInfo] = useState([]);
  useEffect(() => {
    fetchProductData(setProductData);
    //fetchCustomerData(setCustomerData);
    fetchWarehouseData(setWarehouseData);
    handleEditData(id);
  }, []);

  const [totalVal, setTotalVal] = useState({
    stdGoods: 0,
    zeroGoods: 0,
    vatAmt: 0,
    grandTotal: 0,
    totalItems: 0,
    grossWeight: 0,
    netWeight: 0,
    cbm: 0,
    totalProfitPercent: 0,
    totalProfit: 0,
  });
  const [salesDesc, setSalesDesc] = useState([]);

  const [deliveryType, setDeliveryType] = useState('');
  const handleDeliveryandCollectionAddress = (e) => {
    setDeliveryType(e.target.value);
    //setInputDeliveryAddresses([]);
  };
  const [inputCollectionAddresses, setInputCollectionAddresses] = useState({
    address_line1: '',
    address_line2: '',
    city: '',
    country: '',
    post_code: '',
  });


  useEffect(() => {
    $('#dsTable tbody').off('click', '.applybtn');
    $('#dsTable tbody').on('click', '.applybtn', function () {
      $('#freestock').empty();
      $('#stock').empty();
      $('#on_so').empty();
      $('#allocation').empty();
      $('#onpf').empty();
      $('#onpo').empty();
      const product_id = $(this).data('id');
      const formData = new FormData();
      formData.append('id', product_id);
      document.getElementById('viewProductForm').reset();
      const fetchProductData = async () => {
        const apiUrl = `${config.api_url}/ws-get-products-by-id`;
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
            body: formData,
          });

          const data = await response.json();
          $('#code').val(data.products[0].product_desc);
          $('#packValue').val(data.products[0].pack);
          $('#vat').val(data.products[0].vat);
          $('#weight').val(data.products[0].gross_weight);
          $('#supplier').val(data.products[0].supplier_name);
          $('#description').val(data.products[0].product_desc);
          $('#sizeValue').val(data.products[0].size);
          $('#barcode').val(data.products[0].unit_barcode);
          $('#view_case_barcode').val(data.products[0].case_barcode);
          $('#pallet_qty').val(data.products[0].pallet_qty);
          $('#case_price').val(data.products[0].price);
          $("#product_status").html(data.products[0].status != 0
            ? 'Status : <span style="margin-left: 5px;" class="badge bg-success">Active</span>'
            : 'Status : <span style="margin-left: 5px;" class="badge bg-secondary"> Inactive</span>'
          );
          $('#viewcbm').val(data.products[0].cbm);
          $('#brandname').val(data.products[0].brand);
          $('#categooryName').val(data.products[0].category);
          $('#editnetWeight').val(data.products[0].net_weight);
          $('#editgrossWeight').val(data.products[0].gross_weight);
          $('#freestock').append(data.freestock == null ? 0 : data.freestock);
          $('#stock').append(parseInt(data.products[0].quantity));
          $('#on_so').append(data.onso == null ? 0 : data.onso);
          $('#allocation').append(data.allocation == null ? 0 : data.allocation);
          $('#onpf').append(data.pf == null ? 0 : data.pf);
          $('#onpo').append(data.on_purchase == null ? 0 : data.on_purchase);
        }
        catch (error) {
          console.error("Error fetching product data:", error);
        }
      };
      fetchProductData();
    });

    return () => {
      $('#dsTable tbody').off('click', '.applybtn');
    };
  }, []);


  useEffect(() => {

    const calculateTotals = () => {
      const stdGoods = tableRows
        .filter(item => item.vat != '0.00' && item.vat != null)
        .reduce((sum, item) => sum + (parseFloat(item.price) * parseFloat(item.pdtqty)), 0);

      const zeroGoods = tableRows
        .filter(item => item.vat == '0.00' || item.vat == null)
        .reduce((sum, item) => sum + (parseFloat(item.price) * parseFloat(item.pdtqty)), 0);

      const vatAmt = tableRows.reduce((sum, item) => {
        const itemVat = (parseFloat(item.price) * parseFloat(item.pdtqty) * (parseFloat(item.vat) / 100)) || 0;
        return sum + itemVat;
      }, 0);

      const grandTotal = stdGoods + zeroGoods + vatAmt;

      const totalItems = tableRows.reduce((sum, item) => {
        const itemCount = parseInt(item.pdtqty);
        return sum + itemCount;
      }, 0);

      const grossWeight = tableRows.reduce((sum, item) => {
        const grossWeightCount = parseInt(item.pdtqty) * parseFloat(item.grossWeight);
        return sum + grossWeightCount;
      }, 0);

      const netWeight = tableRows.reduce((sum, item) => {
        const netWeightCount = parseInt(item.pdtqty) * parseFloat(item.netWeight);
        return sum + netWeightCount;
      }, 0);

      const cbm = tableRows.reduce((sum, item) => {
        const cbmCount = parseInt(item.pdtqty) * parseFloat(item.cbm);
        return sum + cbmCount;
      }, 0);

      // let totalProfitPercent = tableRows.reduce((profit, row) => profit + ((row.price - row.cost) * (row.pdtqty)), 0);
      // totalProfitPercent = totalProfitPercent / (tableRows.length);
      const totalProfit1 = tableRows.reduce((profit, row) => profit + ((row.price - row.cost) * row.pdtqty), 0);
      const totalCost = tableRows.reduce((cost, row) => cost + (row.cost * row.pdtqty), 0);

      let totalProfitPercent = totalCost > 0 ? (totalProfit1 / totalCost) * 100 : 0;


      console.log(tableRows.length);
      //setGrossProfitPercent(totalProfitPercent);


      const totalProfit = tableRows.reduce((profit, row) => profit + ((row.price - row.cost) * (row.pdtqty)), 0);
      //setGrossProfit(totalProfit);





      setTotalVal({
        stdGoods,
        zeroGoods,
        vatAmt,
        grandTotal,
        totalItems,
        grossWeight,
        netWeight,
        cbm,
        totalProfit,
        totalProfitPercent,
      });
    };

    // Call the function to calculate totals whenever tableRows changes
    calculateTotals();
  }, [tableRows]);

  const submitSalesOrderForm = async (type) => {

    const form = document.getElementById('new_sales_order');
    const formData = new FormData(form);

    let save_status = formData.get('save_as_proforma');
    let delivery_date = formData.get('delivery_date');
    const salesId = formData.get('sales_id');



    let customerId = formData.get('customer_id');
    if (customerId == '') {
      Swal.fire({
        title: "Failed!",
        text: "Please Select Customer.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }

    if (delivery_date == '') {
      formData.set('delivery_date', '0000-00-00');
    }

    let status = 'SO';
    if (save_status == 'on') {
      status = 'PF';
    }
    formData.append(`status`, status);

    if (tableRows.length === 0) {
      Swal.fire({
        title: "Failed!",
        text: "Please Select Product.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    } else {


      tableRows.forEach((row, index) => {
        formData.append(`code[${index}]`, row.code);
        formData.append(`packsize[${index}]`, row.pack);
        formData.append(`productSize[${index}]`, row.size);
        formData.append(`qty[${index}]`, row.pdtqty);
        formData.append(`price[${index}]`, row.price);
        formData.append(`cost[${index}]`, row.cost);
        formData.append(`value[${index}]`, row.value);
        formData.append(`Vat[${index}]`, row.vat);
        formData.append(`onSO[${index}]`, row.onSO !== undefined ? row.onSO : 0);
        formData.append(`fstk[${index}]`, row.fstk !== undefined ? row.fstk : 0);
        formData.append(`product_id[${index}]`, row.id !== undefined ? row.id : 0);
        formData.append(`batch_id[${index}]`, row.batch_id !== undefined ? row.batch_id : 0);
        formData.append(`purchase_id[${index}]`, row.purchase_id !== undefined ? row.purchase_id : 0);
        formData.append(`subtotal[${index}]`, row.value !== undefined ? row.value : 0);

      });

      try {
        const response = await axios.post(`${config.api_url}/ws-update-sales-orderNew`, formData, {
          headers: {
            authorization: `Bearer ${accessToken}`,
          }
        });

        const result = response.data;

        if (result.status === 'success') {
          Swal.fire({
            title: "Success!",
            text: "Order submitted successfully.",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          }).then(() => {
            if (type === 'createPO') {
              navigateToOrderHistory(`/CreatePO/${salesId}`);
            } else if (type === 'leterPO') {
              navigateToOrderHistory('/CurrentSalesOrder');
            }
          });

        } else {
          Swal.fire({
            title: "Failed!",
            text: "Sales Order Failed. Try Again",
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
        }


      } catch (error) {
        console.error("Error accepting order:", error);
      }
    }
  };
  const [selectedDeliveryId, setSelectedDeliveryId] = useState('');
  const handleEditData = async (id) => {
    // alert(id);
    //console.log(id);
    const formData = new FormData();
    formData.set('sale_id', id);
    const response = await axios.post(`${config.api_url}/ws-get-sales-by-id`, formData, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      }
    });


    const data = await response.data;
    console.log(response.data);

    setSalesDesc(data.pdtdesc.bstatus);
    console.log(data.pdtdesc.bstatus);



    if (data.product && data.product.length > 0) {


      const newRows = data.product.map(item => {
        const purchaseIds = Array.isArray(item.purchaseIds)
          ? item.purchaseIds
          : item.purchaseIds ? item.purchaseIds.split(',').map(id => parseInt(id, 10)) : [];

        const batchIds = Array.isArray(item.batchIds)
          ? item.batchIds
          : item.batchIds ? item.batchIds.split(',').map(id => parseInt(id, 10)) : [];



        return {
          id: item.id,
          code: item.code,
          product_desc: item.product_desc,
          pack: item.pack,
          size: item.size,
          price: parseFloat(item.price),
          pdtqty: parseInt(item.salesQty, 10),
          salesQty: parseInt(item.salesQty, 10),
          cost: parseFloat(item.cost),
          quantity: parseInt(item.quantity, 10),
          taxrate: (parseFloat(item.item_tax) / parseFloat(item.price)) * 100 || 0,
          vat: parseFloat(item.vat) || 0,
          value: (parseInt(item.salesQty, 10) * parseFloat(item.price)) || 0,
          profit: parseFloat(item.price) - parseFloat(item.cost),
          sales_item_id: item.sales_item_id,
          receivedQty: item.receivedQty || 0,
          grossWeight: item.gross_weight,
          netWeight: item.net_weight,
          cbm: item.cbm,
          batch_id: batchIds.length > 0 ? batchIds : [0],
          purchase_id: purchaseIds.length > 0 ? purchaseIds : [0], // Ensure it's an array, default to [0]
          mappedPO: '',
          Onpo: item.totalPO || 0,
          fstock: (item.totalPO || 0) + (item.quantity || 0),
          allocation: item.allocation || 0,
          //freestock: item.freestock || 0,
          freestock: (item.freestock !== undefined && item.freestock !== null) ? parseInt(data.freestock) + parseInt(item.quantity) : 0,
          freestockcal: (item.freestock || 0) + parseInt(item.quantity, 10),
          //freestockcal: (item.freestock !== undefined && item.freestock !== null) ? parseInt(data.freestock) + parseInt(item.quantity) : 0,
          onpurchase: item.on_purchase || 0,
        };
      });



      // Update table rows
      setTableRows(prevRows => [...prevRows, ...newRows]);

      if (data.sale.warehouse_id) {
        setSelectedWarehouseId(data.sale.warehouse_id);
      }

      setSalesInfo({
        delivery_date: data.sale.delivery_date,
        delivery_time: data.sale.delivery_time,
        customer_ref: data.sale.customer_ref,
        job_ref: data.sale.job_ref,
        booking_ref: data.sale.booking_ref,
      });


      if (data.sale.customer_id) {

        const selectedOption = await fetchEditCustomerData(setCustomerData, data.sale.customer_id);
        if (selectedOption) {
          setSelectedCustomer(selectedOption);
        }




        handleCustomerChange({ value: data.sale.customer_id }, setInvoiceAddress, setDeliveryAddrDropdown, setDeliveryAddresses);

        if (data.sale.delivery_type == "delivery") {

          const addressData = [
            {
              id: data.sale.delivery_id,
              address_line1: data.sale.address_line1,
              address_line2: data.sale.address_line2,
              city: data.sale.city,
              country: data.sale.country,
              post_code: data.sale.post_code
            }
          ];
          setDeliveryType('delivery');

          setDeliveryAddresses(addressData);
          setSelectedDeliveryId(data.sale.delivery_id);
          getDeliveryAddrById({ value: data.sale.delivery_id }, addressData, setInputDeliveryAddresses);

        } else if (data.sale.delivery_type == "collection") {
          setDeliveryType('collection');
          setInputCollectionAddresses({
            address_line1: data.sale.address_line1,
            address_line2: data.sale.address_line2,
            city: data.sale.city,
            country: data.sale.country,
            post_code: data.sale.post_code,
          });
        }



      }


    } else {
      console.error('No product data found.');
    }


  };






  const getProductBatchData = async (id, salesitemId, batch_id, purchase_id) => {
    try {

      setSelectedBatchId([]);
      setSelectedpurchaseId([]);

      setProductBatch([]);


      const formData = new FormData();
      formData.set('product_id', id);
      formData.set('salesitem_id', salesitemId);

      const response = await axios.post(
        `${config.api_url}/ws-batch-info-by-product-idNew`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );


      const data = response.data;
      //setProductBatch(data.batch);

      const updatedBatch = data.batch.map(item => ({
        ...item,
        batch_id: batch_id,
      }));
      setProductBatch(updatedBatch);


      setSelectedBatchId(batch_id);
      console.log(selectedBatchId);
      setSelectedpurchaseId(purchase_id);

      handleOpenPastPurchaseModal(data.batch);

    } catch (error) {
      console.error("Error fetching product batch data:", error);
    }
  };


  const handleOpenPastPurchaseModal = (data) => {
    setModalData(data);
    if (productBatchRef.current) {
      productBatchRef.current();

    }
  };

  const handleBatchAssignementSubmit = (e) => {

    setSalesBatchToProduct(productBatch[0].product_id, selectedBatchId, selectedpurchaseId, setTableRows);
    closeAssignBatchModal();

    const checkMapped = tableRows.some(row => row.mappedPO !== '') ? 'Unmapped' : 'mapped';
    setSalesDesc(checkMapped);
  };

  const closeAssignBatchModal = (event) => {
    if (AssignBatchModal.current) {
      const bsModal = window.bootstrap.Modal.getInstance(AssignBatchModal.current);
      if (bsModal) bsModal.hide();
    }
  }

  const handleSalesInputChange = (e) => {
    const { name, value } = e.target;
    setSalesInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const handleSalesBatchEdit = (event) => {
    const { value, dataset, checked } = event.target;
    const batchId = Number(value); // Convert value to a number
    const purchaseId = dataset.purchaseid;
    //const batchId = dataset.batchId;




    setSelectedBatchId(prev => {
      if (checked) {
        console.log(`checked`);
        return [...prev, batchId]; // Add to the array
      } else {
        console.log(`unchecked`);
        return prev.filter(id => id !== batchId); // Remove from the array
      }
    });

    console.log(selectedBatchId);



    setSelectedpurchaseId(prev => {
      if (checked) {
        // If checked, add the purchaseId if not already present
        return [...prev, purchaseId];
      } else {
        // If unchecked, remove the purchaseId
        return prev.filter(id => id !== purchaseId);
      }
    });
  };


  const handleSalesBatchNew = (event, setSelectedBatchId, setSelectedpurchaseId) => {

    const { value, dataset, checked } = event.target;
    console.log('Previous selectedBatchId:', selectedBatchId);

    const purchaseId = dataset.purchaseid;
    console.log(purchaseId);

    setSelectedBatchId(prev => {

      if (!Array.isArray(prev)) {
        console.error('prev is not an array:', prev); // This will help identify the issue
        return [value]; // Reset to an array with the current value
      }

      if (checked) {
        console.log(`checked`);
        return [...prev, event.target.value]; // Add to the array
      } else {
        console.log(`unchecked`);
        return prev.filter(id => id !== event.target.value); // Remove from the array
      }
    });

    setSelectedpurchaseId(prev => {

      if (!Array.isArray(prev)) {
        console.error('prev is not an array:', prev); // This will help identify the issue
        return [purchaseId]; // Reset to an array with the current value
      }

      if (checked) {
        console.log(`checked`);
        return [...prev, purchaseId]; // Add to the array
      } else {
        console.log(`unchecked`);
        return prev.filter(id => id !== purchaseId); // Remove from the array
      }
    });


    //setSelectedBatchId(event.target.value);
    //setSelectedpurchaseId(event.target.dataset.purchaseid);
    //setSelectedCost(event.target.dataset.cost);
  };

  const handleShowInvoiceModal = (event) => {
    event.preventDefault();
    const productId = event.currentTarget.getAttribute('data-id');
    GetProductSO(productId);
  };

  const GetProductSO = async (id) => {
    try {
      const formData = new FormData();
      formData.set('product_id', id);

      const response = await axios.post(
        `${config.api_url}/ws-get-product-so`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setProductSO(response.data.sales);
    } catch (error) {
      console.error("Error fetching product PO data:", error);
    }
  };


  return (
    <div>
      <div className="modal fade" id="save-sales" tabIndex="-1">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">&nbsp;</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="add-product-popup">
                {salesDesc !== "Unmapped" ? (

                  <h3> Do you want to update S.O?</h3>
                ) : (
                  <h3> Do you want to create a P.O?</h3>
                )}

                <hr />
                {salesDesc == "Unmapped" ? (
                  <Link to='NewPurchaseOrder' data-bs-dismiss="modal" aria-label="Close" className="popup-innerbtn-common" onClick={() => submitSalesOrderForm('createPO')} >Yes Create P.O</Link>
                ) : ''}
                <Link to='#' onClick={() => submitSalesOrderForm('leterPO')} data-bs-dismiss="modal" aria-label="Close" className="popup-innerbtn-closebtn">Update</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12"> <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="/Dashboard">Dashboard</a> &gt; <span className="last-crumb">Edit New Sales Order</span> </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <form id='new_sales_order'>
          <div className="container-fluid">
            <div className="row">
              <input type="hidden" name="sales_id" value={id} />
              <div className="col-12 col-lg-12 col-sm-12">
                <div className="show-order-area dashboard">
                  <div style={{ marginBottom: '20px', float: 'left' }}>
                    <div className="topsearch-area">
                      <div className="row">
                        <div className="col-12 col-lg-4 col-sm-4">
                          <div className="row">
                            <div className="row mb-3">
                              <label htmlFor="inputText" className="col-sm-3 col-form-label">Customer:</label>
                              <div className="col-sm-6">
                                <Select
                                  isClearable
                                  name="customer_id"
                                  onChange={(e) => handleCustomerChange(e, setInvoiceAddress, setDeliveryAddrDropdown, setDeliveryAddresses, deliveryAddrDropdown)}
                                  options={customerData}
                                  value={selectedCustomer}
                                  placeholder="Select Customer"
                                />


                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-8 col-sm-8">
                          <div style={{ float: "right" }}>
                            <p><label className="checkbox"> <input type="checkbox" name='save_as_proforma' /> <span className="default"></span> </label> <span>Save as Proforma</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="newsalesorderform">
                      <div className="row mb-3 padd-l-13px">
                        <div className="col-6 col-lg-6 col-sm-6">
                          <div className="row mb-3">
                            <div className="col-6 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label"><strong>Invoice Address *</strong></label>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-6 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Address Line 1 </label>
                            </div>
                            <div className="col-6 col-lg-9 col-sm-9">
                              <input type="text" className="form-control" id="addr1" value={invoiceAddress.billing_address_line1} />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-6 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Address Line 2 </label>
                            </div>
                            <div className="col-6 col-lg-9 col-sm-9">
                              <input type="text" className="form-control" id="addr2" value={invoiceAddress.billing_address_line2} />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-6 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">City </label>
                            </div>
                            <div className="col-6 col-lg-3 col-sm-3">
                              <input type="text" className="form-control" value={invoiceAddress.billing_city} />
                            </div>

                            <div className="col-6 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Country </label>
                            </div>
                            <div className="col-6 col-lg-3 col-sm-3">
                              <input type="text" className="form-control" value={invoiceAddress.billing_country} />
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-6 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Post Code </label>
                            </div>
                            <div className="col-6 col-lg-9 col-sm-9">
                              <input type="text" className="form-control" value={invoiceAddress.billing_postcode} />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-6 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">VAT </label>
                            </div>
                            <div className="col-6 col-lg-9 col-sm-9">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Warehouse </label>
                            </div>
                            <div className="col-6 col-lg-9 col-sm-9">


                              <Select
                                name='warehouse_id' className='dynamicselect'
                                id='warehouse_id'
                                isClearable
                                options={warehouseData}
                                placeholder="Select Warehouse"
                                value={warehouseData.find(warehouse => warehouse.value === selectedWarehouseId)} // Set the selected value
                                onChange={option => setSelectedWarehouseId(option?.value || null)} // Update state on selection
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-6 col-lg-6 col-sm-6">

                          <div className="row mb-3">
                            <div className="col-3 col-lg-3 col-sm-3">
                              <label className="col-form-label padd-left-50">
                                Customer Address
                              </label>
                            </div>
                            <div className="col-1 col-lg-1 col-sm-1" style={{ paddingTop: '8px' }}>

                              <input
                                type="radio"
                                name="delivery_type"
                                value="delivery"
                                checked={deliveryType === 'delivery'}
                                onChange={handleDeliveryandCollectionAddress}
                                style={{ float: "left" }}
                              />
                            </div>


                            <div className="col-3 col-lg-3 col-sm-3">
                              <label className="col-form-label padd-left-50">
                                Collection Address
                              </label>
                            </div>
                            <div className="col-3 col-lg-3 col-sm-3" style={{ paddingTop: '8px' }}>

                              <input
                                type="radio"
                                name="delivery_type"
                                value="collection"
                                checked={deliveryType === 'collection'}
                                onChange={handleDeliveryandCollectionAddress}
                                style={{ float: "left" }}
                              />
                            </div>

                          </div>

                          {deliveryType === 'delivery' && (
                            <div>
                              <div className="row mb-3">
                                <div className="col-6 col-lg-3 col-sm-3">
                                  <label htmlFor="desc" className="col-form-label"><strong>Delivery Address </strong></label>
                                </div>
                                <div className="col-6 col-lg-9 col-sm-9">
                                  <select name="delivery_id" className='form-select orgselect' value={selectedDeliveryId} onChange={(e) => {
                                    setSelectedDeliveryId(e.target.value); // Update state on change
                                    getDeliveryAddrById(e, deliveryAddresses, setInputDeliveryAddresses);
                                  }}>
                                    <option value="">Select</option>
                                    {deliveryAddrDropdown.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.address_line1}
                                      </option>
                                    ))}
                                  </select>

                                </div>
                              </div>


                              <div className="row mb-3">
                                <div className="col-4 col-lg-3 col-sm-3">
                                  <label htmlFor="desc" className="col-form-label">Address Line 1 </label>
                                </div>
                                <div className="col-8 col-lg-9 col-sm-9">
                                  <input type="text" name="addr1" className="form-control" readOnly value={inputDeliveryAddresses.address_line1} />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-4 col-lg-3 col-sm-3">
                                  <label htmlFor="desc" className="col-form-label">Address Line 2 </label>
                                </div>
                                <div className="col-8 col-lg-9 col-sm-9">
                                  <input type="text" name="addr2" className="form-control" readOnly value={inputDeliveryAddresses.address_line2} />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-4 col-lg-3 col-sm-3">
                                  <label htmlFor="desc" className="col-form-label">City </label>
                                </div>
                                <div className="col-8 col-lg-3 col-sm-3">
                                  <input type="text" name="city" className="form-control" readOnly value={inputDeliveryAddresses.city} />
                                </div>

                                <div className="col-4 col-lg-3 col-sm-3">
                                  <label htmlFor="desc" className="col-form-label">Country </label>
                                </div>
                                <div className="col-8 col-lg-3 col-sm-3">
                                  <input type="text" name="country" className="form-control" readOnly value={inputDeliveryAddresses.country} />
                                </div>

                              </div>

                              <div className="row mb-3">
                                <div className="col-4 col-lg-3 col-sm-3">
                                  <label htmlFor="desc" className="col-form-label">Post Code </label>
                                </div>
                                <div className="col-8 col-lg-9 col-sm-9">
                                  <input type="text" name="post_code" className="form-control" readOnly value={inputDeliveryAddresses.post_code} />
                                </div>
                              </div>
                            </div>
                          )}
                          {deliveryType === 'collection' && (
                            <div>
                              <div className="row mb-3">
                                <div className="col-3">
                                  <label className="col-form-label">Address Line 1 </label>
                                </div>
                                <div className="col-9">
                                  <input
                                    type="text"
                                    name="addr1"
                                    className="form-control"
                                    value={inputCollectionAddresses.address_line1}
                                    onChange={(e) => setInputCollectionAddresses({ ...inputCollectionAddresses, address_line1: e.target.value })}
                                  />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-3">
                                  <label className="col-form-label">Address Line 2 </label>
                                </div>
                                <div className="col-9">
                                  <input
                                    type="text"
                                    name="addr2"
                                    className="form-control"
                                    value={inputCollectionAddresses.address_line2}
                                    onChange={(e) => setInputCollectionAddresses({ ...inputCollectionAddresses, address_line2: e.target.value })}
                                  />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-3">
                                  <label className="col-form-label">City </label>
                                </div>
                                <div className="col-3">
                                  <input
                                    type="text"
                                    name="city"
                                    className="form-control"
                                    value={inputCollectionAddresses.city}
                                    onChange={(e) => setInputCollectionAddresses({ ...inputCollectionAddresses, city: e.target.value })}
                                  />
                                </div>

                                <div className="col-3">
                                  <label className="col-form-label">Country </label>
                                </div>
                                <div className="col-3">
                                  <input
                                    type="text"
                                    name="country"
                                    className="form-control"
                                    value={inputCollectionAddresses.country}
                                    onChange={(e) => setInputCollectionAddresses({ ...inputCollectionAddresses, country: e.target.value })}
                                  />
                                </div>
                              </div>

                              <div className="row mb-3">
                                <div className="col-3">
                                  <label className="col-form-label">Post Code </label>
                                </div>
                                <div className="col-9">
                                  <input
                                    type="text"
                                    name="post_code"
                                    className="form-control"
                                    value={inputCollectionAddresses.post_code}
                                    onChange={(e) => setInputCollectionAddresses({ ...inputCollectionAddresses, post_code: e.target.value })}
                                  />
                                </div>
                              </div>
                            </div>
                          )}



                          <div className="row mb-3">
                            <div className="col-4 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Delivery Date </label>
                            </div>
                            <div className="col-6 col-lg-3 col-sm-3">
                              <input type="date" className="form-control" name="delivery_date" id="delivery_date" value={salesInfo.delivery_date} onChange={handleSalesInputChange} />
                            </div>
                            <div className="col-6 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Delivery Time </label>
                            </div>
                            <div className="col-6 col-lg-3 col-sm-3">
                              <input type="time" className="form-control" name="delivery_time" id="delivery_time" value={salesInfo.delivery_time} onChange={handleSalesInputChange} />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-6 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Customer Ref *</label>
                            </div>
                            <div className="col-6 col-lg-9 col-sm-9">
                              <input type="text" className="form-control" name="customer_ref" id="customer_ref" value={salesInfo.customer_ref} onChange={handleSalesInputChange} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Job Ref </label>
                            </div>
                            <div className="col-6 col-lg-3 col-sm-3">
                              <input type="text" className="form-control" name="job_ref" id="job_ref" value={salesInfo.job_ref} onChange={handleSalesInputChange} />
                            </div>
                            <div className="col-6 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">Booking Ref </label>
                            </div>
                            <div className="col-6 col-lg-3 col-sm-3">
                              <input type="text" className="form-control" name="booking_ref" id="booking_ref" value={salesInfo.booking_ref} onChange={handleSalesInputChange} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="topsearch-area">
                      <div className="row">
                        <div className="col-12 col-lg-9 col-sm-9">&nbsp;</div>
                        <div className="col-12 col-lg-3 col-sm-3">
                          <div className="col-12 col-lg-12 col-sm-12">
                            <div className='row'>
                              <label htmlFor="inputText" className=" col-form-label col-12 col-lg-4 col-sm-4" >
                                Product:
                              </label>
                              <div className="col-12 col-lg-8 col-sm-8">
                                <Select
                                  isClearable
                                  onChange={(e) => handleSalesProductAddNew(e, tableRows, setTableRows)}
                                  options={productData}
                                  placeholder="Select Product"
                                />
                              </div>
                            </div>

                          </div>

                        </div>



                      </div>
                    </div>
                    <div className="verical-scrl-wrapper common-table newsalesorder-table" id="style-3">
                      <table className="table table-bordered dataTable" id="dsTable">
                        <thead>
                          <tr>
                            <th>Code</th>
                            <th>Description</th>
                            <th>Pk</th>
                            <th>Size</th>
                            <th>Case Qty</th>
                            <th>Price</th>
                            <th>Cost</th>
                            <th>Value</th>
                            <th>Profit</th>
                            <th>VAT</th>
                            <th>Stock</th>
                            <th>On PO</th>
                            <th>F.Stk</th>
                            <th>Mapped P.O No.</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableRows.length === 0 ? (
                            <tr style={{ textAlign: "center" }}>
                              <td colSpan={13}>No product added</td>
                            </tr>
                          ) : (
                            tableRows.map((row) => (
                              <tr key={row.id}>
                                <td>


                                  <input type="hidden" name="product_id[]" value={row.id} />
                                  <input type="hidden" name="code[]" value={row.code} />
                                  <input type="hidden" name="batch_id[]" value={row.batch_id} />
                                  <input type="hidden" name="purchase_id[]" value={row.purchase_id} />

                                  <input type="hidden" name="code[]" value={row.code} />


                                  <Link
                                    to="#"
                                    onClick={() => getProductBatchData(row.id, row.sales_item_id, row.batch_id, row.purchase_id)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#viw-pst-invs"
                                  >
                                    {row.code}
                                  </Link>

                                </td>
                                <td>
                                  <p className="cut-text">
                                    <a
                                      data-bs-toggle="modal"
                                      className="applybtn"
                                      data-id={row.id}
                                      data-bs-target="#viewproduct"
                                    >
                                      {row.product_desc}
                                    </a>
                                  </p>
                                </td>
                                <td>
                                  <input
                                    type="hidden"
                                    name="packsize[]"
                                    value={row.pack}
                                  />{" "}
                                  {row.pack}
                                </td>
                                <td>
                                  <input
                                    type="hidden"
                                    name="productSize[]"
                                    value={row.size}
                                  />{" "}
                                  {row.size}
                                </td>
                                <td>


                                  <input
                                    type="text"
                                    name="qty[]"
                                    value={row.pdtqty}
                                    className="form-control qtyinputbox"
                                    onChange={(e) => handleInputChangeIneditSales(e, row.id, "pdtqty", setTableRows)}
                                    autoComplete='off'
                                  />
                                </td>
                                <td>

                                  <input
                                    type="number"
                                    name="price[]"
                                    className="form-control qtyinputbox"
                                    value={row.price}
                                    onChange={(e) => handleInputChangeInSalesNew(e, row.id, "price", setTableRows)}
                                    step="0.01"
                                    autoComplete='off'
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="cost[]"
                                    className="form-control qtyinputbox"
                                    value={row.cost}
                                    onChange={(e) => handleInputChangeInSalesNew(e, row.id, "cost", setTableRows)}
                                    step="0.01" // Allow decimal input
                                    autoComplete='off'
                                  />
                                </td>
                                <td>
                                  <input type="hidden" name="sales_item_id[]" value={row.sales_item_id ? row.sales_item_id : 0} />
                                  <input type="hidden" name="value[]" value={row.value.toFixed(2)} />
                                  {row.value.toFixed(2)}
                                </td>
                                <td>
                                  <input type="hidden" name="profit[]" value={(row.price - row.cost).toFixed(2)} />
                                  {((row.price - row.cost) * (row.pdtqty)).toFixed(2)}
                                </td>
                                <td>
                                  <input type="hidden" name="vat[]" value={((row.value / 100) * row.vat).toFixed(2)} />
                                  {((row.value / 100) * row.vat).toFixed(2)}
                                </td>
                                <td>
                                  <input type="hidden" name="stock[]" value={row.receivedQty} />

                                  {row.quantity}
                                </td>
                                <td>
                                  <input type="hidden" name="onPO[]" />

                                  {/*row.Onpo*/}
                                  {row.onpurchase}
                                </td>
                                <td>
                                  <input type="hidden" name="onSO[]" value="0" />
                                  {/* ((Number(row.quantity) || 0) + (Number(row.Onpo) || 0)) - (Number(row.allocation)) */}
                                  {/*(Number(row.freestock)) + (Number(row.quantity)) */}

                                  {row.freestockcal}
                                </td>
                                <td>
                                  <input type="hidden" name="fstk[]" value="0" />


                                  {row.batch_id != 0 ? (
                                    row.purchase_id != 0 && row.purchase_id.length > 0 ? (
                                      row.purchase_id.map((id, index) => (
                                        <span key={index}>
                                          PO{id}{index < row.purchase_id.length - 1 ? ', ' : ''}
                                        </span>
                                      ))
                                    ) : ''
                                  ) : (
                                    <span>{row.mappedPO ? row.mappedPO : ''}</span>
                                  )}
                                </td>
                                <td>
                                  <Link
                                    To="#"
                                    value="Remove"
                                    onClick={() =>
                                      removeRow(row.id, setTableRows)
                                    }
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Link>
                                </td>



                                <input type="hidden" name="stdGoods" value={totalVal.stdGoods.toFixed(2)} />
                                <input type="hidden" name="vatAmt" value={totalVal.vatAmt.toFixed(2)} />
                                <input type="hidden" name="zeroGoods" value={totalVal.zeroGoods.toFixed(2)} />
                                <input type="hidden" name="grandTotal" value={totalVal.grandTotal.toFixed(2)} />
                                <input type="hidden" name="totalItems" value={totalVal.totalItems} />
                                <input type="hidden" name="grossWeight" value={totalVal.grossWeight} />
                                <input type="hidden" name="netWeight" value={totalVal.netWeight} />
                                <input type="hidden" name="cube" value={totalVal.cbm} />
                                <input type="hidden" name="grossProfit" value={totalVal.totalProfit.toFixed(2)} />
                                <input type="hidden" name="grossProfitPercent" value={totalVal.totalProfitPercent.toFixed(2)} />

                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="newsalesorderftr">
                      <div className="row">
                        <div className="col-6 col-lg-3 col-sm-3">Std Rated : {totalVal.stdGoods.toFixed(2)} </div>
                        <div className="col-6 col-lg-3 col-sm-3">VAT Amount : {totalVal.vatAmt.toFixed(2)} </div>
                        <div className="col-6 col-lg-3 col-sm-3">Zero Rated : {totalVal.zeroGoods.toFixed(2)}</div>
                        <div className="col-6 col-lg-3 col-sm-3">Grand Total : {totalVal.grandTotal.toFixed(2)}</div>
                      </div>
                    </div>
                    <div className="newsalesorderftr">
                      <div className="row">
                        <div className="col-6 col-lg-3 col-sm-3">Total Items :  {totalVal.totalItems} </div>
                        <div className="col-6 col-lg-3 col-sm-3">Gross Weight : {totalVal.grossWeight} KGS</div>
                        <div className="col-6 col-lg-3 col-sm-3">Net Weight : {totalVal.netWeight} KGS</div>
                        <div className="col-6 col-lg-3 col-sm-3">Cube : {totalVal.cbm} M3</div>
                      </div>
                    </div>
                    <div className="newsalesorderftr">
                      <div className="row">
                        <div className="col-6 col-lg-6 col-sm-6">Gross Profit : {totalVal.totalProfit.toFixed(2)}</div>
                        <div className="col-6 col-lg-6 col-sm-6">Gross Profit % : {totalVal.totalProfitPercent.toFixed(2)}%</div>
                      </div>
                    </div>
                    <div>
                      <div className="row mb-3">
                        <div className="col-6 col-lg-3 col-sm-3">
                          <label htmlFor="desc" className="col-form-label">Notes :</label></div>
                        <div className="col-6 col-lg-9 col-sm-9">
                          <textarea className="form-control" name="notes" id="notes" placeholder="Leave a comment here" style={{ height: '100px' }}></textarea>
                        </div>
                      </div>
                    </div>
                    <a href="#" className="popup-innerbtn-common" data-bs-toggle="modal" data-bs-target="#save-sales">Save Sales Order</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ViewProduct />
      <div className="modal fade" id="viw-pst-invs" tabIndex="-1" ref={AssignBatchModal}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">All Batches :  {productBatch[0]?.product_desc}  ({productBatch[0]?.code})</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="partialbox">
                <h3>
                  <a href='#' data-bs-toggle="modal" data-bs-target="#vw-pst-ordr" data-id={productBatch[0]?.product_id} onClick={handleShowInvoiceModal}>View Past Invoices</a></h3>
                <form id='selectBatchForm'>
                  <table className="table table-bordered dataTable">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>

                        <th>Stock Qty</th>
                        <th>Sold Qty</th>
                        <th>Bal Qty</th>
                        <th>Alloc</th>
                        <th>UnAlloc</th>
                        <th>PO No.</th>
                        <th>Net Price</th>
                        <th>Received</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productBatch.map((item, index) => (
                        <tr key={index}>
                          <td>



                            <input
                              type="checkbox"
                              id={`batch_${item.id}`}
                              name="select_batch"
                              value={item.id}
                              data-purchaseid={item.purchase_id}
                              data-unallocation={item.qty - item.allocation}
                              data-cost={item.cost_price}
                              onChange={(e) => handleSalesBatchNew(e, setSelectedBatchId, setSelectedpurchaseId)}
                              defaultChecked={Array.isArray(selectedBatchId) && selectedBatchId.includes(item.id)} style={{ marginRight: '10px'}}
                            />



                            <label htmlFor={`batch_${item.id}`}>Select Batch</label>
                          </td>

                          <td>{item.qty}</td>
                          <td>{item.sale_qty}</td>
                          <td>{item.qty - item.sale_qty}</td>
                          <td>{item.allocation}</td>
                          <td>{item.qty - item.allocation}</td>
                          <td>

                            {item.reference_no}

                          </td>
                          <td>{item.cost_price}</td>
                          <td>{item.received_quantity}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <a className="popup-innerbtn-common" onClick={handleBatchAssignementSubmit} style={{ marginTop: '10px' }} > Submit</a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="vw-pst-ordr" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">&nbsp;All Invoices of Product : {productBatch[0]?.product_desc}  ({productBatch[0]?.code})</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="partialbox">
                <table className="table table-bordered dataTable">
                  <thead>
                    <tr>
                      <th>SO#</th>
                      <th>Qty</th>
                      <th>Price </th>
                      <th>Delivered on</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productSO.map((item, index) => (
                      <tr>
                        <td>{item.reference_no}</td>
                        <td>{item.quantity}</td>
                        <td>{item.price}</td>
                        <td>{item.delivery_date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default NewSalesOrderEdit; 
