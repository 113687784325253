import jsPDF from 'jspdf';
import 'jspdf-autotable';
import config from '../Common_Files/Config';

const footerSections1 = [
[
{ text: 'Notes: Delivered on 15/08; N5267', size: 10, bold: false },
{ text: 'Ref: VBG 5KG; Transportify', size: 10, bold: false },
{ text: '', size: 10, bold: false },
{ text: 'Beneficiary Bank Details:', size: 10, bold: false },
{ text: 'Bank Name : HSBC', size: 10, bold: false },
{ text: 'Bank Acc. # : 260183041180', size: 10, bold: false },
{ text: 'Swift Code : HSBCSGSG', size: 10, bold: false },
{ text: 'Bank Address : 21 COLLYER QUAY #01-01 HSBC BLDG SINGAPORE', size: 10, bold: false }
],
[
{ text: '', size: 10, bold: true },
],
[
{ text: '', size: 10, bold: false },
],
[
{ text: 'Gross Weight: 25,00 KGS', size: 10, bold: false },
{ text: 'CBM: 50M3', size: 10, bold: false },
{ text: 'Net weight: 15,000 KGS', size: 10, bold: false },
]
];




const footerSections2 = [
[
{ text: 'Subject to Star Pacific Limited terms and conditions of sale, a copy of which is available on request', size: 8, bold: false },
{ text: 'NBC (No. 7298396)', size: 10, bold: false },
{ text: 'Reservation of Title. The property in and the title to all goods sold by NBC shall not pass to the buyer until all monies due and owing have been paid in full', size: 8, bold: false },
]
];

const generatePDF = async (id) => {
  try {
    // Fetch data from your API
    
    const formData = new FormData();
        formData.append('id', id); // Append the id
        const response = await fetch(`${config.api_url}/ws-get-purchase-history`, {
            method: 'POST', // Use POST method
            body: formData, // Use the FormData object
          });



        const data = await response.json();
        const newPdtArray = data.products;
        const doc = new jsPDF('landscape');
        const pageWidth = doc.internal.pageSize.getWidth();



    // Header Sections with Different Formatting
    const headerSections1 = [
      // First section: Company name and address
      [
      { text: 'NBC', size: 13, bold: true },
      { text: 'Abenglen Industrial Estate,', size: 8, bold: false },
      { text: 'Betam Road, Hayes', size: 8, bold: false },
      { text: 'Middlesex, London UB3 1AT', size: 8, bold: false },
      { text: 'United Kingdom', size: 8, bold: false },
      ],
      // Second section: Registration details
      [
      { text: 'Co. Reg. No.: 7298396', size: 8, bold: true },
      { text: 'VAT Reg. No.: GB994533184', size: 8, bold: false },
      { text: 'Tel: 44-208 329 3800', size: 8, bold: false },
      ],
      // Third section: Empty column
      [
      { text: '', size: 8, bold: false },
      ],
      // Fourth section: Empty column
      [
      { text: '', size: 8, bold: false },
      ],
      ];


      const headerSections2 = [
      [
      { text: 'Invoice Address: ALLIANCE INTERNATIONAL LTD ALLIANCE INTERNATIONAL LTD', size: 8, bold: false, },
      { text: 'UNIT 6 ABENGLEN INDUSTRIAL', size: 8, bold: false },
      { text: 'ESTATE', size: 8, bold: false },
      { text: 'MIDDLESEX', size: 8, bold: false },
      { text: 'HAYES UB3 1SS', size: 8, bold: false },
      { text: 'United Kingdom', size: 8, bold: false }
      ],
      [
      { text: 'VAT Reg. No.: ', size: 8, bold: true },
      { text: '300583441', size: 8, bold: false },
      { text: 'Rep: RAJ SINGH', size: 8, bold: false },
      { text: 'Account No: ALI001', size: 8, bold: false }
      ],
      [
      { text: 'Tax Invoice No. & Date:', size: 8, bold: false },
      { text: ':3900389418 06.07.2021', size: 8, bold: false },
      { text: '', size: 8, bold: false },
      { text: 'Jon Ref: N5424', size: 8, bold: false }
      ],
      [
      { text: 'Currency : GBP', size: 8, bold: false },
      { text: 'Customer Ref: 05148562', size: 8, bold: false },
      { text: 'Booking Ref: 5258463', size: 8, bold: false },
      { text: 'Delivery Date: 20/08/2024', size: 8, bold: false },
      { text: 'SO#:', size: 8, bold: false }
      ]
      ];

      const headerSections3 = [
      [
      { text: 'Delivery Address: ALLIANCE INTERNATIONAL LTD', size: 8, bold: false },
      { text: 'UNIT 6 ABENGLEN INDUSTRIAL,', size: 8, bold: false },
      { text: 'ESTATE', size: 8, bold: false },
      { text: 'MIDDLESEX', size: 8, bold: false },
      { text: 'HAYES UB3 1SS', size: 8, bold: false },
      { text: 'United Kingdom', size: 8, bold: false },
      ],
      [
      { text: '', size: 8, bold: true },
      ],
      [
      { text: 'Payment Terms:', size: 8, bold: false },
      { text: '14 Days from Date of Invoice', size: 8, bold: false },
      { text: '', size: 8, bold: false },
      { text: 'Due Date: 15.08.2021', size: 8, bold: false },
      ],
      [
      { text: '', size: 8, bold: false }
      ]
      ];

      const sectionWidth = pageWidth / 4;
    let y = 10; // Starting y position

    // Add Header Sections 1
    headerSections1.forEach((section, index) => {
      const colStartX = (index % 4) * sectionWidth + 10; // Calculate X position for each column

      section.forEach((item, i) => {
        doc.setFontSize(item.size);
        doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
        doc.text(item.text, colStartX, y + i * 5); // Reduced line height to 6
      });

      if (index % 4 === 3) { // Move to the next row after every four columns
        y += 20; // Increase y position for new row
      }
    });

    // Add horizontal line after headerSections2 is complete
    const lineStartX = 10; // Starting X position for the line
    const lineEndX = pageWidth - 10; // Ending X position for the line
    const lineY = y + 10; // Y position for the line, slightly below the last header section

    doc.setLineWidth(0.1); // Set line width

    // Set draw color with opacity (RGBA - Red, Green, Blue, Alpha)
    doc.setDrawColor(0, 0, 0, 0.3); // Black color with 50% opacity (alpha = 0.5)

    doc.line(lineStartX, lineY, lineEndX, lineY); // Draw the line
    y += 16; // Increase y position for any subsequent content

    // y += 7; // Add space between header sections

    // Add Header Sections 2
    headerSections2.forEach((section, index) => {
      const sectionWidth = 75; // Set the fixed width for each column
      const colStartX = (index % 4) * sectionWidth + 10; // Calculate X position for each column

      // Starting Y position for this section
      let currentY = y;

      section.forEach((item, i) => {
        doc.setFontSize(item.size);
        doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');

        // Split text to fit within the sectionWidth
        const splitText = doc.splitTextToSize(item.text, sectionWidth);


        // Render the wrapped text
        doc.text(splitText, colStartX, currentY);

        // Adjust Y position for the next line
        currentY += 5 * splitText.length; // Adjust line height based on splitText length
      });

      // Move to the next row after every four columns
      if (index % 4 === 3) {
        y += 30; // Increase y position for new row
      }
    });

    // Add horizontal line after headerSections2 is complete
    const lineStartX2 = 10; // Starting X position for the line
    const lineEndX2 = pageWidth - 10; // Ending X position for the line
    const lineY2 = y + 10; // Y position for the line, slightly below the last header section

    doc.setLineWidth(0.1); // Set line width

    // Set draw color with opacity (RGBA - Red, Green, Blue, Alpha)
    doc.setDrawColor(0, 0, 0, 0.3); // Black color with 50% opacity (alpha = 0.5)

    doc.line(lineStartX2, lineY2, lineEndX2, lineY2); // Draw the line
    y += 16; // Increase y position for any subsequent content

    // Add Header Sections 3
    headerSections3.forEach((section, index) => {
      const colStartX = (index % 4) * sectionWidth + 10; // Calculate X position for each column

      section.forEach((item, i) => {
        doc.setFontSize(item.size);
        doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
        doc.text(item.text, colStartX, y + i * 5); // Reduced line height to 6
      });

      if (index % 4 === 3) { // Move to the next row after every four columns
        y += 20; // Increase y position for new row
      }
    });

    // Add horizontal line after headerSections2 is complete
    const lineStartX3 = 10; // Starting X position for the line
    const lineEndX3 = pageWidth - 10; // Ending X position for the line
    const lineY3 = y + 10; // Y position for the line, slightly below the last header section

    doc.setLineWidth(0.1); // Set line width

    // Set draw color with opacity (RGBA - Red, Green, Blue, Alpha)
    doc.setDrawColor(0, 0, 0, 0.3); // Black color with 50% opacity (alpha = 0.5)

    doc.line(lineStartX3, lineY3, lineEndX3, lineY3); // Draw the line
    y += 16; // Increase y position for any subsequent content

    y += 5; // Add space before the table

    // Table Columns
    const columns = [
    { title: 'Line No', dataKey: 'line_no', size: 8, bold: false },
    { title: 'SKU Code', dataKey: 'sku_code', size: 8, bold: false },
    { title: 'Product Description', dataKey: 'pdt_desc', size: 8, bold: false },
    { title: 'Pack', dataKey: 'pack', size: 8, bold: false },
    { title: 'Size', dataKey: 'size', size: 8, bold: false },
    { title: 'Unit Brcode', dataKey: 'unit_brcode', size: 8, bold: false },
    { title: 'Case Qty', dataKey: 'case_qty', size: 8, bold: false },
    { title: 'Unit Price', dataKey: 'unit_price', size: 8, bold: false },
    { title: 'Amount', dataKey: 'amount', size: 8, bold: false },
    { title: 'Vat Rate(%)', dataKey: 'vat_rate', size: 8, bold: false },
    { title: 'Vat Amount', dataKey: 'vat_amount', size: 8, bold: false },
    { title: 'Total Amount', dataKey: 'total', size: 8, bold: false }
    ];

    // Add Table
    doc.autoTable({
      startY: y,
      head: [columns.map(col => col.title)],
      body: newPdtArray.map((item, index) => {
                const netPrice = item.quantity * item.price; // Total for the row
                const vatAmount = netPrice * (item.rate / 100); // VAT amount
                const totalPrice = netPrice + vatAmount; // Total including VAT

                return [
                index + 1, 
                item.code, 
                item.product_desc,
                item.pack,
                item.size,
                item.unit_brcode,
                item.quantity,
                item.price, 
                    netPrice, // Total for the row
                    item.rate,
                    vatAmount, // VAT amount
                    totalPrice // Total including VAT
                    ];
                  }),
      theme: 'grid',
      headStyles: { fillColor: '#f8f9fa', textColor: '#404040', size: '13px' },
      margin: { top: 10 },
    });


    // Get the position where the table ended
    const finalY = doc.autoTable.previous.finalY;

    // Add Footer Sections 1
    y = finalY + 55; // Space after table

  // Assuming y is set to the position after your table
let footerStartY = doc.internal.pageSize.height - 30; // Start 30 units from the bottom

// Before rendering the footer, check if there's enough space left on the current page
if (footerStartY < 225) { // Check if the space is less than 225
    doc.addPage(); // Add a new page for the footer
    //footerStartY = doc.internal.pageSize.height - 30; // Reset Y position for the new page
    footerStartY = 30;
  }

// Render footer sections
footerSections1.forEach((section, index) => {
    const colStartX = (index % 4) * sectionWidth + 10; // Calculate X position for each column

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
        doc.text(item.text, colStartX, footerStartY + i * 6); // Reduced line height to 6
      });

    if (index % 4 === 3) { // Move to the next row after every four columns
        footerStartY += 20; // Increase Y position for new row
      }
    });

    // Add Footer Sections 2
    y = doc.internal.pageSize.height + 30; // Position 30 units from the bottom
    const centerX = pageWidth / 2; // Center X position

     const textHeight = 20; // Estimated height of each line (adjust as needed)

    // Check if there's enough space for the next line of text
    // After rendering the table and getting finalY
    y = finalY + 50; // Space after the table

    // Prepare for footer rendering
    let footerStartY5 = doc.internal.pageSize.height - 30; // Start from 30 units from the bottom

    // Check if there's enough space for footerSections2
    if (footerStartY < 40) { // If less than 40 units from the bottom
      doc.addPage(); // Add a new page
      footerStartY = 30; // Reset Y position
    }

    // Render footer sections 2
    footerSections2.forEach((section, index) => {
      const colStartX = (index % 2) * sectionWidth + 10; // Two columns for footer section 2
      section.forEach((item, i) => {
        doc.setFontSize(item.size);
        doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
        doc.text(item.text, colStartX, y + i * 5);
      });

      // Move to the next row after every two columns
      if (index % 2 === 1) {
        y += 20;
      }
    });

    // Save PDF
    doc.save('Invoice-01.pdf');

  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};
export default generatePDF;
