import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../Common_Files/Header";
import config from "../Common_Files/Config";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5";
// import generatePDFNew from "../Purchase/PurchaseHistoryInvoice";
import "rsuite/dist/rsuite.min.css";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const InvoiceView = () => {
  const { id } = useParams();
  const accessToken = localStorage.getItem("accessToken");
  const [productList, setProductList] = useState([]);
  const [supplierAddress, setSupplierAddress] = useState([]);
  const [caseQTY, setCaseQTY] = useState(0);
  const [palletQTY, setPalletQTY] = useState(0);

  let subtotal = 0;
  let totalvat = 0;
  let total = 0;
  let gross_weight = 0;
  let net_weight = 0;
  let cbm = 0;

  const handleGeneratePDF = () => {
    const input = document.getElementById('invoice'); 

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgWidth = 190; // Adjust as needed
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('invoice.pdf');
    });
  };




  const fetchData = async () => {
    const formData = new FormData();
    formData.append("sale_id", id);
    const apiUrl = `${config.api_url}/ws-get-sales-by-id`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch purchase data");
      }
      const data = await response.json();
      setProductList(data.product);
      setSupplierAddress(data.sale);


      let a = data.product;
      const totalCaseQty = a.reduce((accumulator, product) => {
        return accumulator + parseInt(product.salesQty);
    }, 0);
  
      setCaseQTY(totalCaseQty);
  
      const totalPalletQty = a.reduce((accumulator, product) => {
        return accumulator + parseInt(product.pallet_qty);
      }, 0);
    setPalletQTY(totalPalletQty);

    } catch (error) {
      console.error("Error fetching purchase data:", error);
    }
  };
  
  useEffect(() => {
    if (!accessToken) 
      {
       window.location.href = "/login";
       return;
      }
    fetchData();
  }, [setProductList]);

  const calculateAmount = (quantity, price) => {
    const qty = Number(quantity);
    const prc = Number(price);
    return isNaN(qty) || isNaN(prc) ? 0 : qty * prc;
  };
  const calculateVAT = (amount, rate) => {
    const amt = Number(amount);
    const r = Number(rate);
    return isNaN(amt) || isNaN(r) ? 0 : (amt * r) / 100;
  };

  const calculateTotalPrice = (quantity, price, rate) => {
    const amt = calculateAmount(quantity, price); // Ensure this returns a number
    const vat = calculateVAT(amt, rate); // Ensure this returns a number
    return isNaN(amt) || isNaN(vat) ? 0 : amt + vat; // Return 0 if either is NaN
  };

  // const handleGeneratePurchaseInvoicePDF = (id) => {
  //   generatePDFNew(id);
  // };

  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#">
                <i className="fa fa-home"></i>
              </a>{" "}
              &gt; <a href="dashboard">Dashboard</a> &gt;{" "}
              <span className="last-crumb">Purchase Order </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="show-order-area dashboard">
            <div
              className="sales-view-area"
              style={{ width: "100%", border: "none" }}
            >
              <div className="row">
                <div className="col-7">
                  <button onClick={(e) => handleGeneratePDF(id)}>
                    Generate PDF
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-7"></div>
              </div>

              <div className="row page-header" style={{ paddingTop: "10px" }}>
                <div className="col-4">
                  <h5>NBC</h5>
                  <p>
                   Abenglen Industrial Estate
                    <br />
                    Middlesex, London UB3 1AT
                    <br />
                    United Kingdom
                  </p>
                </div>
                <div className="col-4">
                  <ul>
                    <li>
                      <b>Co. Reg. No :</b> 7298396
                    </li>
                    <li>
                      <span>VAT Reg. No.:</span> GB994533184
                    </li>
                    <li>
                      <span>Tel :</span> 44-208 329 3800
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row page-header" style={{ paddingTop: "10px" }}>
                <div className="col-8">
                  <ul>
                    <li>
                      <b>Delivery Address : </b> {supplierAddress.address_line1}
                    </li>
                    <li>
                      <span> {supplierAddress.address_line2}</span>
                    </li>
                    <li>
                      <span> {supplierAddress.city}</span>
                    </li>
                    <li>
                      <span> {supplierAddress.post_code}</span>
                    </li>
                    <li>
                      <span>
                        {supplierAddress.country}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-4">
                  <ul>
                  <li>
                      <span>Customer Ref :</span> {supplierAddress.reference_no}
                    </li>
                    <li>
                      <span>Booking Ref :</span> {}
                    </li>
                    <li>
                      <span>Booking Time :</span> {supplierAddress.order_date}
                    </li>
                    <li>
                      <span>Delivery Date :</span> {supplierAddress.delivery_date}
                    </li>
                    <li>
                      <span>Delivery Note :</span> {supplierAddress.notes}
                    </li>
                    <li>
                      <span>SO# :</span> {supplierAddress.reference_no}
                    </li>
                  </ul>
                </div>
                
              </div>
              <div className="row page-header" style={{ paddingTop: "10px" }}>
                <div className="col-3">
                  {supplierAddress.delivery_type === "collection" && (
                    <ul>
                      <li>
                        <b>Collection Address : </b>{" "}
                        {supplierAddress.collection_address_line1}
                      </li>
                      <li>
                        <span>{supplierAddress.collection_address_line2}</span>
                      </li>
                      <li>
                        <span>{supplierAddress.collection_city}</span>
                      </li>
                      <li>
                        <span>{supplierAddress.collection_state}</span>
                      </li>
                      <li>
                        <span>
                          {supplierAddress.collection_country}{" "}
                          {supplierAddress.collection_postcode}
                        </span>
                      </li>
                    </ul>
                  )}

                  {supplierAddress.is_wharehouse_or_customer === "warehouse" &&
                    supplierAddress.delivery_type === "delivery" && (
                      <ul>
                        <li>
                          <b>Delivery Address : </b> {supplierAddress.name}
                        </li>
                        <li>
                          <span>{supplierAddress.address_line1}</span>
                        </li>
                        <li>
                          <span>{supplierAddress.address_line2}</span>
                        </li>
                        <li>
                          <span>{supplierAddress.city}</span>
                        </li>
                        <li>
                          <span>{supplierAddress.state}</span>
                        </li>
                        <li>
                          <span>
                            {supplierAddress.country} {supplierAddress.postcode}
                          </span>
                        </li>
                      </ul>
                    )}

                  {supplierAddress.is_wharehouse_or_customer === "customer" &&
                    supplierAddress.delivery_type === "delivery" && (
                      <ul>
                        <li>
                          <b>Customer Address : </b> {supplierAddress.name}
                        </li>
                        <li>
                          <span>
                            {supplierAddress.customer_delivery_address_line1}
                          </span>
                        </li>
                        <li>
                          <span>
                            {supplierAddress.customer_delivery_address_line2}
                          </span>
                        </li>
                        <li>
                          <span>{supplierAddress.customer_delivery_city}</span>
                        </li>
                        <li>
                          <span>{supplierAddress.customer_delivery_state}</span>
                        </li>
                        <li>
                          <span>
                            {supplierAddress.customer_delivery_country}{" "}
                            {supplierAddress.customer_delivery_postcode}
                          </span>
                        </li>
                      </ul>
                    )}
                </div>
                <div className="col-3"></div>
              </div>
              <div className="sales-view-table">
                <table className="table table-bordered" id="purchaseProforma">
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "#eee9e9" }}>Line No</th>
                      <th style={{ backgroundColor: "#eee9e9" }}>SKU Code</th>
                      <th style={{ backgroundColor: "#eee9e9" }}>
                        Product Description
                      </th>
                      <th style={{ backgroundColor: "#eee9e9" }}>Pack</th>
                      <th style={{ backgroundColor: "#eee9e9" }}>Size</th>
                      <th style={{ backgroundColor: "#eee9e9" }}>
                        Unit Barcode
                      </th>
                      <th style={{ backgroundColor: "#eee9e9" }}>Case Qty</th>
                      <th style={{ backgroundColor: "#eee9e9" }}>Pallet Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productList.map((product, index) => {
                      const amount = calculateAmount(
                        product.quantity,
                        product.cost
                      );
                      const vat = calculateVAT(amount, product.rate);
                      const totalPrice = calculateTotalPrice(
                        product.quantity,
                        product.cost,
                        product.rate
                      );

                      // Update subtotal
                      subtotal += amount;
                      totalvat += vat;
                      total = subtotal + totalvat;

                      gross_weight = product.gross_weight;
                      net_weight = product.net_weight;
                      cbm = product.cbm;
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{product.code}</td>
                          <td>{product.product_desc}</td>
                          <td>{product.pack}</td>
                          <td>{product.size}</td>
                          <td>{product.unit_barcode}</td>
                          <td>{product.salesQty}</td>
                          <td>{product.pallet_qty}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={6} style={{ textAlign: "right", fontWeight: "bold" }} > Total: </td>
                      <td>{caseQTY}</td>
                      <td>{palletQTY}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className="row page-header" style={{ paddingTop: "10px" }}>
                <div className="col-8">
                  <ul>
                    <li>
                      <span>Notes : </span> Delivered on {supplierAddress.delivery_date}
                    </li>
                    <li>
                      <span>Ref : </span> Delivered on {supplierAddress.refno}
                    </li>
                    <li></li>
                  </ul>
                </div>
                <div className="col-4">
                  <ul>
                    <li>
                      <span>Gross Weight: {supplierAddress.gross_weight} KGS</span>
                    </li>
                    <li>
                      <span>CBM: {supplierAddress.cube} M3</span>
                    </li>
                    <li>
                      <span>Net weight: {supplierAddress.net_weight} KGS</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row page-header" style={{ paddingTop: "10px" }}>
                <div className="col-4">
                  <ul>
                    <li><span>CHEP Standard</span></li>
                    <li><span>LPR Standard</span></li>
                    <li><span>IPP Standard</span></li>
                    <li><span>CHEP Euro</span></li>
                    <li><span>Others</span></li>
                  </ul>
                </div>
                <div className="col-4">
                <ul>
                    <li><span>Driver</span></li>
                    <li><span>Loaded in good condition and correct pallet count</span></li>
                    <li><span>Name _____________</span></li>
                    <li><span>Sign _____________</span></li>
                  </ul>
                </div>
                <div className="col-4">
                  <ul>
                    <li><span>Warehouse</span></li>
                    <li><span>Visual inspection of pallets and product carried out</span></li>
                    <li><span>Name _____________</span></li>
                    <li><span>Sign _____________</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="text-center mt-4">
        <p className="lh-base">
          <strong>
            Subject to Star Pacific Limited terms and conditions of sale, a copy
            of which is available on request.
          </strong>
          <br />
        </p>
        <p>NBC (No. 7298396) </p>
        <p>
          Reservation of Title. The property in and the title to all goods sold
          by NBC shall not pass to the buyer until all monies
          due and owing have been paid in full
        </p>
      </footer>
    </div>
  );
};

export default InvoiceView;
